import { vLearnApiUrl } from '../api/config';
import { formatDate, parseInteger } from './constant';

const profileSteps = [
  {
    index: 1,
    stepName: 'Personal details'
  },
  {
    index: 2,
    stepName: 'Professional information'
  },
  {
    index: 3,
    stepName: 'Educational qualifications'
  },
  {
    index: 4,
    stepName: 'Skill proficiency'
  },
  {
    index: 5,
    stepName: 'Placements'
  }
];

const allCities = [
  { id: 1, name: 'Nicobar', state: 'Andaman Nicobar' },
  { id: 2, name: 'North Middle Andaman', state: 'Andaman Nicobar' },
  { id: 3, name: 'South Andaman', state: 'Andaman Nicobar' },
  { id: 4, name: 'Anantapur', state: 'Andhra Pradesh' },
  { id: 5, name: 'Chittoor', state: 'Andhra Pradesh' },
  { id: 6, name: 'East Godavari', state: 'Andhra Pradesh' },
  { id: 7, name: 'Alluri Sitarama Raju', state: 'Andhra Pradesh' },
  { id: 8, name: 'Anakapalli', state: 'Andhra Pradesh' },
  { id: 9, name: 'Annamaya', state: 'Andhra Pradesh' },
  { id: 10, name: 'Bapatla', state: 'Andhra Pradesh' },
  { id: 11, name: 'Eluru', state: 'Andhra Pradesh' },
  { id: 12, name: 'Guntur', state: 'Andhra Pradesh' },
  { id: 13, name: 'Kadapa', state: 'Andhra Pradesh' },
  { id: 14, name: 'Kakinada', state: 'Andhra Pradesh' },
  { id: 15, name: 'Konaseema', state: 'Andhra Pradesh' },
  { id: 16, name: 'Krishna', state: 'Andhra Pradesh' },
  { id: 17, name: 'Kurnool', state: 'Andhra Pradesh' },
  { id: 18, name: 'Manyam', state: 'Andhra Pradesh' },
  { id: 19, name: 'N T Rama Rao', state: 'Andhra Pradesh' },
  { id: 20, name: 'Nandyal', state: 'Andhra Pradesh' },
  { id: 21, name: 'Nellore', state: 'Andhra Pradesh' },
  { id: 22, name: 'Palnadu', state: 'Andhra Pradesh' },
  { id: 23, name: 'Prakasam', state: 'Andhra Pradesh' },
  { id: 24, name: 'Sri Balaji', state: 'Andhra Pradesh' },
  { id: 25, name: 'Sri Satya Sai', state: 'Andhra Pradesh' },
  { id: 26, name: 'Srikakulam', state: 'Andhra Pradesh' },
  { id: 27, name: 'Visakhapatnam', state: 'Andhra Pradesh' },
  { id: 28, name: 'Vizianagaram', state: 'Andhra Pradesh' },
  { id: 29, name: 'West Godavari', state: 'Andhra Pradesh' },
  { id: 30, name: 'Anjaw', state: 'Arunachal Pradesh' },
  { id: 31, name: 'Central Siang', state: 'Arunachal Pradesh' },
  { id: 32, name: 'Changlang', state: 'Arunachal Pradesh' },
  { id: 33, name: 'Dibang Valley', state: 'Arunachal Pradesh' },
  { id: 34, name: 'East Kameng', state: 'Arunachal Pradesh' },
  { id: 35, name: 'East Siang', state: 'Arunachal Pradesh' },
  { id: 36, name: 'Kamle', state: 'Arunachal Pradesh' },
  { id: 37, name: 'Kra Daadi', state: 'Arunachal Pradesh' },
  { id: 38, name: 'Kurung Kumey', state: 'Arunachal Pradesh' },
  { id: 39, name: 'Lepa Rada', state: 'Arunachal Pradesh' },
  { id: 40, name: 'Lohit', state: 'Arunachal Pradesh' },
  { id: 41, name: 'Longding', state: 'Arunachal Pradesh' },
  { id: 42, name: 'Lower Dibang Valley', state: 'Arunachal Pradesh' },
  { id: 43, name: 'Lower Siang', state: 'Arunachal Pradesh' },
  { id: 44, name: 'Lower Subansiri', state: 'Arunachal Pradesh' },
  { id: 45, name: 'Namsai', state: 'Arunachal Pradesh' },
  { id: 46, name: 'Pakke Kessang', state: 'Arunachal Pradesh' },
  { id: 47, name: 'Papum Pare', state: 'Arunachal Pradesh' },
  { id: 48, name: 'Shi Yomi', state: 'Arunachal Pradesh' },
  { id: 49, name: 'Tawang', state: 'Arunachal Pradesh' },
  { id: 50, name: 'Tirap', state: 'Arunachal Pradesh' },
  { id: 51, name: 'Upper Siang', state: 'Arunachal Pradesh' },
  { id: 52, name: 'Upper Subansiri', state: 'Arunachal Pradesh' },
  { id: 53, name: 'West Kameng', state: 'Arunachal Pradesh' },
  { id: 54, name: 'West Siang', state: 'Arunachal Pradesh' },
  { state: 'Assam', name: 'Bajali' },
  { state: 'Assam', name: 'Baksa' },
  { state: 'Assam', name: 'Barpeta' },
  { state: 'Assam', name: 'Biswanath' },
  { state: 'Assam', name: 'Bongaigaon' },
  { state: 'Assam', name: 'Cachar' },
  { state: 'Assam', name: 'Charaideo' },
  { state: 'Assam', name: 'Chirang' },
  { state: 'Assam', name: 'Darrang' },
  { state: 'Assam', name: 'Dhemaji' },
  { state: 'Assam', name: 'Dhubri' },
  { state: 'Assam', name: 'Dibrugarh' },
  { state: 'Assam', name: 'Dima Hasao' },
  { state: 'Assam', name: 'Goalpara' },
  { state: 'Assam', name: 'Golaghat' },
  { state: 'Assam', name: 'Hailakandi' },
  { state: 'Assam', name: 'Hojai' },
  { state: 'Assam', name: 'Jorhat' },
  { state: 'Assam', name: 'Kamrup' },
  { state: 'Assam', name: 'Kamrup Metropolitan' },
  { state: 'Assam', name: 'Karbi Anglong' },
  { state: 'Assam', name: 'Karimganj' },
  { state: 'Assam', name: 'Kokrajhar' },
  { state: 'Assam', name: 'Lakhimpur' },
  { state: 'Assam', name: 'Majuli' },
  { state: 'Assam', name: 'Morigaon' },
  { state: 'Assam', name: 'Nagaon' },
  { state: 'Assam', name: 'Nalbari' },
  { state: 'Assam', name: 'Sivasagar' },
  { state: 'Assam', name: 'Sonitpur' },
  { state: 'Assam', name: 'South Salmara-Mankachar' },
  { state: 'Assam', name: 'Tamulpur' },
  { state: 'Assam', name: 'Tinsukia' },
  { state: 'Assam', name: 'Udalguri' },
  { state: 'Assam', name: 'West Karbi Anglong' },
  { state: 'Bihar', name: 'Araria' },
  { state: 'Bihar', name: 'Arwal' },
  { state: 'Bihar', name: 'Aurangabad' },
  { state: 'Bihar', name: 'Banka' },
  { state: 'Bihar', name: 'Begusarai' },
  { state: 'Bihar', name: 'Bhagalpur' },
  { state: 'Bihar', name: 'Bhojpur' },
  { state: 'Bihar', name: 'Buxar' },
  { state: 'Bihar', name: 'Darbhanga' },
  { state: 'Bihar', name: 'East Champaran' },
  { state: 'Bihar', name: 'Gaya' },
  { state: 'Bihar', name: 'Gopalganj' },
  { state: 'Bihar', name: 'Jamui' },
  { state: 'Bihar', name: 'Jehanabad' },
  { state: 'Bihar', name: 'Kaimur' },
  { state: 'Bihar', name: 'Katihar' },
  { state: 'Bihar', name: 'Khagaria' },
  { state: 'Bihar', name: 'Kishanganj' },
  { state: 'Bihar', name: 'Lakhisarai' },
  { state: 'Bihar', name: 'Madhepura' },
  { state: 'Bihar', name: 'Madhubani' },
  { state: 'Bihar', name: 'Munger' },
  { state: 'Bihar', name: 'Muzaffarpur' },
  { state: 'Bihar', name: 'Nalanda' },
  { state: 'Bihar', name: 'Nawada' },
  { state: 'Bihar', name: 'Patna' },
  { state: 'Bihar', name: 'Purnia' },
  { state: 'Bihar', name: 'Rohtas' },
  { state: 'Bihar', name: 'Saharsa' },
  { state: 'Bihar', name: 'Samastipur' },
  { state: 'Bihar', name: 'Saran' },
  { state: 'Bihar', name: 'Sheikhpura' },
  { state: 'Bihar', name: 'Sheohar' },
  { state: 'Bihar', name: 'Sitamarhi' },
  { state: 'Bihar', name: 'Siwan' },
  { state: 'Bihar', name: 'Supaul' },
  { state: 'Bihar', name: 'Vaishali' },
  { state: 'Bihar', name: 'West Champaran' },
  { state: 'Chandigarh', name: 'Chandigarh' },
  { state: 'Chhattisgarh', name: 'Balod' },
  { state: 'Chhattisgarh', name: 'Baloda Bazar' },
  { state: 'Chhattisgarh', name: 'Balrampur' },
  { state: 'Chhattisgarh', name: 'Bastar' },
  { state: 'Chhattisgarh', name: 'Bemetara' },
  { state: 'Chhattisgarh', name: 'Bijapur' },
  { state: 'Chhattisgarh', name: 'Bilaspur' },
  { state: 'Chhattisgarh', name: 'Dantewada' },
  { state: 'Chhattisgarh', name: 'Dhamtari' },
  { state: 'Chhattisgarh', name: 'Durg' },
  { state: 'Chhattisgarh', name: 'Gariaband' },
  { state: 'Chhattisgarh', name: 'Gaurela Pendra Marwahi' },
  { state: 'Chhattisgarh', name: 'Janjgir Champa' },
  { state: 'Chhattisgarh', name: 'Jashpur' },
  { state: 'Chhattisgarh', name: 'Kabirdham' },
  { state: 'Chhattisgarh', name: 'Kanker' },
  { state: 'Chhattisgarh', name: 'Khairagarh' },
  { state: 'Chhattisgarh', name: 'Kondagaon' },
  { state: 'Chhattisgarh', name: 'Korba' },
  { state: 'Chhattisgarh', name: 'Koriya' },
  { state: 'Chhattisgarh', name: 'Mahasamund' },
  { state: 'Chhattisgarh', name: 'Manendragarh' },
  { state: 'Chhattisgarh', name: 'Mohla Manpur' },
  { state: 'Chhattisgarh', name: 'Mungeli' },
  { state: 'Chhattisgarh', name: 'Narayanpur' },
  { state: 'Chhattisgarh', name: 'Raigarh' },
  { state: 'Chhattisgarh', name: 'Raipur' },
  { state: 'Chhattisgarh', name: 'Rajnandgaon' },
  { state: 'Chhattisgarh', name: 'Sakti' },
  { state: 'Chhattisgarh', name: 'Sarangarh Bilaigarh' },
  { state: 'Chhattisgarh', name: 'Sukma' },
  { state: 'Chhattisgarh', name: 'Surajpur' },
  { state: 'Chhattisgarh', name: 'Surguja' },
  {
    state: 'Dadra and Nagar Haveli and Daman and Diu',
    name: 'Dadra and Nagar Haveli'
  },
  { state: 'Dadra and Nagar Haveli and Daman and Diu', name: 'Daman' },
  { state: 'Dadra and Nagar Haveli and Daman and Diu', name: 'Diu' },
  { state: 'Delhi', name: 'New Delhi' },
  { state: 'Goa', name: 'North Goa' },
  { state: 'Goa', name: 'South Goa' },
  { state: 'Gujarat', name: 'Ahmedabad' },
  { state: 'Gujarat', name: 'Amreli' },
  { state: 'Gujarat', name: 'Anand' },
  { state: 'Gujarat', name: 'Aravalli' },
  { state: 'Gujarat', name: 'Banaskantha' },
  { state: 'Gujarat', name: 'Bharuch' },
  { state: 'Gujarat', name: 'Bhavnagar' },
  { state: 'Gujarat', name: 'Botad' },
  { state: 'Gujarat', name: 'Chhota Udaipur' },
  { state: 'Gujarat', name: 'Dahod' },
  { state: 'Gujarat', name: 'Dang' },
  { state: 'Gujarat', name: 'Devbhumi Dwarka' },
  { state: 'Gujarat', name: 'Gandhinagar' },
  { state: 'Gujarat', name: 'Gir Somnath' },
  { state: 'Gujarat', name: 'Jamnagar' },
  { state: 'Gujarat', name: 'Junagadh' },
  { state: 'Gujarat', name: 'Kutch' },
  { state: 'Gujarat', name: 'Kheda' },
  { state: 'Gujarat', name: 'Mahisagar' },
  { state: 'Gujarat', name: 'Mehsana' },
  { state: 'Gujarat', name: 'Morbi' },
  { state: 'Gujarat', name: 'Narmada' },
  { state: 'Gujarat', name: 'Navsari' },
  { state: 'Gujarat', name: 'Panchmahal' },
  { state: 'Gujarat', name: 'Patan' },
  { state: 'Gujarat', name: 'Porbandar' },
  { state: 'Gujarat', name: 'Rajkot' },
  { state: 'Gujarat', name: 'Sabarkantha' },
  { state: 'Gujarat', name: 'Surat' },
  { state: 'Gujarat', name: 'Surendranagar' },
  { state: 'Gujarat', name: 'Tapi' },
  { state: 'Gujarat', name: 'Vadodara' },
  { state: 'Gujarat', name: 'Valsad' },
  { state: 'Haryana', name: 'Ambala' },
  { state: 'Haryana', name: 'Bhiwani' },
  { state: 'Haryana', name: 'Charkhi Dadri' },
  { state: 'Haryana', name: 'Faridabad' },
  { state: 'Haryana', name: 'Fatehabad' },
  { state: 'Haryana', name: 'Gurgaon' },
  { state: 'Haryana', name: 'Hisar' },
  { state: 'Haryana', name: 'Jhajjar' },
  { state: 'Haryana', name: 'Jind' },
  { state: 'Haryana', name: 'Kaithal' },

  { state: 'Haryana', name: 'Karnal' },
  { state: 'Haryana', name: 'Kurukshetra' },
  { state: 'Haryana', name: 'Mahendragarh' },
  { state: 'Haryana', name: 'Mewat' },
  { state: 'Haryana', name: 'Palwal' },
  { state: 'Haryana', name: 'Panchkula' },
  { state: 'Haryana', name: 'Panipat' },
  { state: 'Haryana', name: 'Rewari' },
  { state: 'Haryana', name: 'Rohtak' },
  { state: 'Haryana', name: 'Sirsa' },
  { state: 'Haryana', name: 'Sonipat' },
  { state: 'Haryana', name: 'Yamunanagar' },
  { state: 'Himachal Pradesh', name: 'Bilaspur' },
  { state: 'Himachal Pradesh', name: 'Chamba' },
  { state: 'Himachal Pradesh', name: 'Hamirpur' },
  { state: 'Himachal Pradesh', name: 'Kangra' },
  { state: 'Himachal Pradesh', name: 'Kinnaur' },
  { state: 'Himachal Pradesh', name: 'Kullu' },
  { state: 'Himachal Pradesh', name: 'Lahaul Spiti' },
  { state: 'Himachal Pradesh', name: 'Mandi' },
  { state: 'Himachal Pradesh', name: 'Shimla' },
  { state: 'Himachal Pradesh', name: 'Sirmaur' },
  { state: 'Himachal Pradesh', name: 'Solan' },
  { state: 'Himachal Pradesh', name: 'Una' },
  { state: 'Jammu Kashmir', name: 'Anantnag' },
  { state: 'Jammu Kashmir', name: 'Bandipora' },
  { state: 'Jammu Kashmir', name: 'Baramulla' },
  { state: 'Jammu Kashmir', name: 'Budgam' },
  { state: 'Jammu Kashmir', name: 'Doda' },
  { state: 'Jammu Kashmir', name: 'Ganderbal' },
  { state: 'Jammu Kashmir', name: 'Jammu' },
  { state: 'Jammu Kashmir', name: 'Kathua' },
  { state: 'Jammu Kashmir', name: 'Kishtwar' },
  { state: 'Jammu Kashmir', name: 'Kulgam' },
  { state: 'Jammu Kashmir', name: 'Kupwara' },
  { state: 'Jammu Kashmir', name: 'Poonch' },
  { state: 'Jammu Kashmir', name: 'Pulwama' },
  { state: 'Jammu Kashmir', name: 'Rajouri' },
  { state: 'Jammu Kashmir', name: 'Ramban' },
  { state: 'Jammu Kashmir', name: 'Reasi' },
  { state: 'Jammu Kashmir', name: 'Samba' },
  { state: 'Jammu Kashmir', name: 'Shopian' },
  { state: 'Jammu Kashmir', name: 'Srinagar' },
  { state: 'Jammu Kashmir', name: 'Udhampur' },
  { state: 'Jharkhand', name: 'Bokaro' },
  { state: 'Jharkhand', name: 'Chatra' },
  { state: 'Jharkhand', name: 'Deoghar' },
  { state: 'Jharkhand', name: 'Dhanbad' },
  { state: 'Jharkhand', name: 'Dumka' },
  { state: 'Jharkhand', name: 'East Singhbhum' },
  { state: 'Jharkhand', name: 'Garhwa' },
  { state: 'Jharkhand', name: 'Giridih' },
  { state: 'Jharkhand', name: 'Godda' },
  { state: 'Jharkhand', name: 'Gumla' },
  { state: 'Jharkhand', name: 'Hazaribagh' },
  { state: 'Jharkhand', name: 'Jamtara' },
  { state: 'Jharkhand', name: 'Khunti' },
  { state: 'Jharkhand', name: 'Koderma' },
  { state: 'Jharkhand', name: 'Latehar' },
  { state: 'Jharkhand', name: 'Lohardaga' },
  { state: 'Jharkhand', name: 'Pakur' },
  { state: 'Jharkhand', name: 'Palamu' },
  { state: 'Jharkhand', name: 'Ramgarh' },
  { state: 'Jharkhand', name: 'Ranchi' },
  { state: 'Jharkhand', name: 'Sahebganj' },
  { state: 'Jharkhand', name: 'Seraikela Kharsawan' },
  { state: 'Jharkhand', name: 'Simdega' },
  { state: 'Jharkhand', name: 'West Singhbhum' },
  { state: 'Karnataka', name: 'Bagalkot' },
  { state: 'Karnataka', name: 'Bangalore Rural' },
  { state: 'Karnataka', name: 'Bangalore Urban' },
  { state: 'Karnataka', name: 'Belgaum' },
  { state: 'Karnataka', name: 'Bellary' },
  { state: 'Karnataka', name: 'Bidar' },
  { state: 'Karnataka', name: 'Chamarajanagar' },
  { state: 'Karnataka', name: 'Chikkaballapur' },
  { state: 'Karnataka', name: 'Chikkamagaluru' },
  { state: 'Karnataka', name: 'Chitradurga' },
  { state: 'Karnataka', name: 'Dakshina Kannada' },
  { state: 'Karnataka', name: 'Davanagere' },
  { state: 'Karnataka', name: 'Dharwad' },
  { state: 'Karnataka', name: 'Gadag' },
  { state: 'Karnataka', name: 'Gulbarga' },
  { state: 'Karnataka', name: 'Hassan' },
  { state: 'Karnataka', name: 'Haveri' },
  { state: 'Karnataka', name: 'Kodagu' },
  { state: 'Karnataka', name: 'Kolar' },
  { state: 'Karnataka', name: 'Koppal' },
  { state: 'Karnataka', name: 'Mandya' },
  { state: 'Karnataka', name: 'Mysore' },
  { state: 'Karnataka', name: 'Raichur' },
  { state: 'Karnataka', name: 'Ramanagara' },
  { state: 'Karnataka', name: 'Shimoga' },
  { state: 'Karnataka', name: 'Tumkur' },
  { state: 'Karnataka', name: 'Udupi' },
  { state: 'Karnataka', name: 'Uttara Kannada' },
  { state: 'Karnataka', name: 'Vijayanagara' },
  { state: 'Karnataka', name: 'Vijayapura' },
  { state: 'Karnataka', name: 'Yadgir' },
  { state: 'Kerala', name: 'Alappuzha' },
  { state: 'Kerala', name: 'Ernakulam' },
  { state: 'Kerala', name: 'Idukki' },
  { state: 'Kerala', name: 'Kannur' },
  { state: 'Kerala', name: 'Kasaragod' },
  { state: 'Kerala', name: 'Kollam' },
  { state: 'Kerala', name: 'Kottayam' },
  { state: 'Kerala', name: 'Kozhikode' },
  { state: 'Kerala', name: 'Malappuram' },
  { state: 'Kerala', name: 'Palakkad' },
  { state: 'Kerala', name: 'Pathanamthitta' },
  { state: 'Kerala', name: 'Thiruvananthapuram' },
  { state: 'Kerala', name: 'Thrissur' },
  { state: 'Kerala', name: 'Wayanad' },
  { state: 'Ladakh', name: 'Kargil' },
  { state: 'Ladakh', name: 'Leh' },
  { state: 'Lakshadweep', name: 'Lakshadweep' },
  { state: 'Madhya Pradesh', name: 'Agar Malwa' },
  { state: 'Madhya Pradesh', name: 'Alirajpur' },
  { state: 'Madhya Pradesh', name: 'Anuppur' },
  { state: 'Madhya Pradesh', name: 'Ashoknagar' },
  { state: 'Madhya Pradesh', name: 'Balaghat' },
  { state: 'Madhya Pradesh', name: 'Barwani' },
  { state: 'Madhya Pradesh', name: 'Betul' },
  { state: 'Madhya Pradesh', name: 'Bhind' },
  { state: 'Madhya Pradesh', name: 'Bhopal' },
  { state: 'Madhya Pradesh', name: 'Burhanpur' },
  { state: 'Madhya Pradesh', name: 'Chachaura' },
  { state: 'Madhya Pradesh', name: 'Chhatarpur' },
  { state: 'Madhya Pradesh', name: 'Chhindwara' },
  { state: 'Madhya Pradesh', name: 'Damoh' },
  { state: 'Madhya Pradesh', name: 'Datia' },
  { state: 'Madhya Pradesh', name: 'Dewas' },
  { state: 'Madhya Pradesh', name: 'Dhar' },
  { state: 'Madhya Pradesh', name: 'Dindori' },
  { state: 'Madhya Pradesh', name: 'Guna' },
  { state: 'Madhya Pradesh', name: 'Gwalior' },
  { state: 'Madhya Pradesh', name: 'Harda' },
  { state: 'Madhya Pradesh', name: 'Hoshangabad' },
  { state: 'Madhya Pradesh', name: 'Indore' },
  { state: 'Madhya Pradesh', name: 'Jabalpur' },
  { state: 'Madhya Pradesh', name: 'Jhabua' },
  { state: 'Madhya Pradesh', name: 'Katni' },
  { state: 'Madhya Pradesh', name: 'Khandwa' },
  { state: 'Madhya Pradesh', name: 'Khargone' },
  { state: 'Madhya Pradesh', name: 'Maihar' },
  { state: 'Madhya Pradesh', name: 'Mandla' },
  { state: 'Madhya Pradesh', name: 'Mandsaur' },
  { state: 'Madhya Pradesh', name: 'Morena' },
  { state: 'Madhya Pradesh', name: 'Nagda' },
  { state: 'Madhya Pradesh', name: 'Narsinghpur' },
  { state: 'Madhya Pradesh', name: 'Neemuch' },
  { state: 'Madhya Pradesh', name: 'Niwari' },
  { state: 'Madhya Pradesh', name: 'Panna' },
  { state: 'Madhya Pradesh', name: 'Raisen' },
  { state: 'Madhya Pradesh', name: 'Rajgarh' },
  { state: 'Madhya Pradesh', name: 'Ratlam' },
  { state: 'Madhya Pradesh', name: 'Rewa' },
  { state: 'Madhya Pradesh', name: 'Sagar' },
  { state: 'Madhya Pradesh', name: 'Satna' },
  { state: 'Madhya Pradesh', name: 'Sehore' },
  { state: 'Madhya Pradesh', name: 'Seoni' },
  { state: 'Madhya Pradesh', name: 'Shahdol' },
  { state: 'Madhya Pradesh', name: 'Shajapur' },
  { state: 'Madhya Pradesh', name: 'Sheopur' },
  { state: 'Madhya Pradesh', name: 'Shivpuri' },
  { state: 'Madhya Pradesh', name: 'Sidhi' },
  { state: 'Madhya Pradesh', name: 'Singrauli' },
  { state: 'Madhya Pradesh', name: 'Tikamgarh' },
  { state: 'Madhya Pradesh', name: 'Ujjain' },
  { state: 'Madhya Pradesh', name: 'Umaria' },
  { state: 'Madhya Pradesh', name: 'Vidisha' },
  { state: 'Maharashtra', name: 'Ahmednagar' },
  { state: 'Maharashtra', name: 'Akola' },
  { state: 'Maharashtra', name: 'Amravati' },
  { state: 'Maharashtra', name: 'Aurangabad' },
  { state: 'Maharashtra', name: 'Beed' },
  { state: 'Maharashtra', name: 'Bhandara' },
  { state: 'Maharashtra', name: 'Buldhana' },
  { state: 'Maharashtra', name: 'Chandrapur' },
  { state: 'Maharashtra', name: 'Dhule' },
  { state: 'Maharashtra', name: 'Gadchiroli' },
  { state: 'Maharashtra', name: 'Gondia' },
  { state: 'Maharashtra', name: 'Hingoli' },
  { state: 'Maharashtra', name: 'Jalgaon' },
  { state: 'Maharashtra', name: 'Jalna' },
  { state: 'Maharashtra', name: 'Kolhapur' },
  { state: 'Maharashtra', name: 'Latur' },
  { state: 'Maharashtra', name: 'Mumbai City' },
  { state: 'Maharashtra', name: 'Mumbai Suburban' },
  { state: 'Maharashtra', name: 'Nagpur' },
  { state: 'Maharashtra', name: 'Nanded' },
  { state: 'Maharashtra', name: 'Nandurbar' },
  { state: 'Maharashtra', name: 'Nashik' },
  { state: 'Maharashtra', name: 'Osmanabad' },
  { state: 'Maharashtra', name: 'Palghar' },
  { state: 'Maharashtra', name: 'Parbhani' },
  { state: 'Maharashtra', name: 'Pune' },
  { state: 'Maharashtra', name: 'Raigad' },
  { state: 'Maharashtra', name: 'Ratnagiri' },
  { state: 'Maharashtra', name: 'Sangli' },
  { state: 'Maharashtra', name: 'Satara' },
  { state: 'Maharashtra', name: 'Sindhudurg' },
  { state: 'Maharashtra', name: 'Solapur' },
  { state: 'Maharashtra', name: 'Thane' },
  { state: 'Maharashtra', name: 'Wardha' },
  { state: 'Maharashtra', name: 'Washim' },
  { state: 'Maharashtra', name: 'Yavatmal' },
  { state: 'Manipur', name: 'Bishnupur' },
  { state: 'Manipur', name: 'Chandel' },
  { state: 'Manipur', name: 'Churachandpur' },
  { state: 'Manipur', name: 'Imphal East' },
  { state: 'Manipur', name: 'Imphal West' },
  { state: 'Manipur', name: 'Jiribam' },
  { state: 'Manipur', name: 'Kakching' },
  { state: 'Manipur', name: 'Kamjong' },
  { state: 'Manipur', name: 'Kangpokpi' },
  { state: 'Manipur', name: 'Noney' },
  { state: 'Manipur', name: 'Pherzawl' },
  { state: 'Manipur', name: 'Senapati' },
  { state: 'Manipur', name: 'Tamenglong' },
  { state: 'Manipur', name: 'Tengnoupal' },
  { state: 'Manipur', name: 'Thoubal' },
  { state: 'Manipur', name: 'Ukhrul' },
  { state: 'Meghalaya', name: 'East Garo Hills' },
  { state: 'Meghalaya', name: 'East Jaintia Hills' },
  { state: 'Meghalaya', name: 'East Khasi Hills' },
  { state: 'Meghalaya', name: 'North Garo Hills' },
  { state: 'Meghalaya', name: 'Ri-Bhoi' },
  { state: 'Meghalaya', name: 'South Garo Hills' },
  { state: 'Meghalaya', name: 'South West Garo Hills' },
  { state: 'Meghalaya', name: 'South West Khasi Hills' },
  { state: 'Meghalaya', name: 'West Garo Hills' },
  { state: 'Meghalaya', name: 'West Jaintia Hills' },
  { state: 'Meghalaya', name: 'West Khasi Hills' },
  { state: 'Mizoram', name: 'Aizawl East' },
  { state: 'Mizoram', name: 'Aizawl West' },
  { state: 'Mizoram', name: 'Champhai' },
  { state: 'Mizoram', name: 'Hnahthial' },
  { state: 'Mizoram', name: 'Khawzawl' },
  { state: 'Mizoram', name: 'Kolasib' },
  { state: 'Mizoram', name: 'Lawngtlai' },
  { state: 'Mizoram', name: 'Lunglei' },
  { state: 'Mizoram', name: 'Mamit' },
  { state: 'Mizoram', name: 'Saiha' },
  { state: 'Mizoram', name: 'Saitual' },
  { state: 'Mizoram', name: 'Serchhip' },
  { state: 'Nagaland', name: 'Dimapur' },
  { state: 'Nagaland', name: 'Kiphire' },
  { state: 'Nagaland', name: 'Kohima' },
  { state: 'Nagaland', name: 'Longleng' },
  { state: 'Nagaland', name: 'Mokokchung' },
  { state: 'Nagaland', name: 'Mon' },
  { state: 'Nagaland', name: 'Peren' },
  { state: 'Nagaland', name: 'Phek' },
  { state: 'Nagaland', name: 'Tuensang' },
  { state: 'Nagaland', name: 'Wokha' },
  { state: 'Nagaland', name: 'Zunheboto' },
  { state: 'Odisha', name: 'Angul' },
  { state: 'Odisha', name: 'Balangir' },
  { state: 'Odisha', name: 'Balasore' },
  { state: 'Odisha', name: 'Bargarh' },
  { state: 'Odisha', name: 'Bhadrak' },
  { state: 'Odisha', name: 'Boudh' },
  { state: 'Odisha', name: 'Cuttack' },
  { state: 'Odisha', name: 'Deogarh' },
  { state: 'Odisha', name: 'Dhenkanal' },
  { state: 'Odisha', name: 'Gajapati' },
  { state: 'Odisha', name: 'Ganjam' },
  { state: 'Odisha', name: 'Jagatsinghpur' },
  { state: 'Odisha', name: 'Jajpur' },
  { state: 'Odisha', name: 'Jharsuguda' },
  { state: 'Odisha', name: 'Kalahandi' },
  { state: 'Odisha', name: 'Kandhamal' },
  { state: 'Odisha', name: 'Kendrapara' },
  { state: 'Odisha', name: 'Kendujhar' },
  { state: 'Odisha', name: 'Khordha' },
  { state: 'Odisha', name: 'Koraput' },
  { state: 'Odisha', name: 'Malkangiri' },
  { state: 'Odisha', name: 'Mayurbhanj' },
  { state: 'Odisha', name: 'Nabarangpur' },
  { state: 'Odisha', name: 'Nayagarh' },
  { state: 'Odisha', name: 'Nuapada' },
  { state: 'Odisha', name: 'Puri' },
  { state: 'Odisha', name: 'Rayagada' },
  { state: 'Odisha', name: 'Sambalpur' },
  { state: 'Odisha', name: 'Subarnapur' },
  { state: 'Odisha', name: 'Sundargarh' },
  { state: 'Puducherry', name: 'Karaikal' },
  { state: 'Puducherry', name: 'Mahe' },
  { state: 'Puducherry', name: 'Pondicherry' },
  { state: 'Puducherry', name: 'Yanam' },
  { state: 'Punjab', name: 'Amritsar' },
  { state: 'Punjab', name: 'Barnala' },
  { state: 'Punjab', name: 'Bathinda' },
  { state: 'Punjab', name: 'Faridkot' },
  { state: 'Punjab', name: 'Fatehgarh Sahib' },
  { state: 'Punjab', name: 'Fazilka' },
  { state: 'Punjab', name: 'Ferozepur' },
  { state: 'Punjab', name: 'Gurdaspur' },
  { state: 'Punjab', name: 'Hoshiarpur' },
  { state: 'Punjab', name: 'Jalandhar' },
  { state: 'Punjab', name: 'Kapurthala' },
  { state: 'Punjab', name: 'Ludhiana' },
  { state: 'Punjab', name: 'Mansa' },
  { state: 'Punjab', name: 'Moga' },
  { state: 'Punjab', name: 'Muktsar' },
  { state: 'Punjab', name: 'Nawanshahr' },
  { state: 'Punjab', name: 'Pathankot' },
  { state: 'Punjab', name: 'Patiala' },
  { state: 'Punjab', name: 'Rupnagar' },
  { state: 'Punjab', name: 'Sahibzada Ajit Singh Nagar' },
  { state: 'Punjab', name: 'Sangrur' },
  { state: 'Punjab', name: 'Shahid Bhagat Singh Nagar' },
  { state: 'Punjab', name: 'Sri Muktsar Sahib' },
  { state: 'Punjab', name: 'Tarn Taran' },
  { state: 'Rajasthan', name: 'Ajmer' },
  { state: 'Rajasthan', name: 'Alwar' },
  { state: 'Rajasthan', name: 'Banswara' },
  { state: 'Rajasthan', name: 'Baran' },
  { state: 'Rajasthan', name: 'Barmer' },
  { state: 'Rajasthan', name: 'Bharatpur' },
  { state: 'Rajasthan', name: 'Bhilwara' },
  { state: 'Rajasthan', name: 'Bikaner' },
  { state: 'Rajasthan', name: 'Bundi' },
  { state: 'Rajasthan', name: 'Chittorgarh' },
  { state: 'Rajasthan', name: 'Churu' },
  { state: 'Rajasthan', name: 'Dausa' },
  { state: 'Rajasthan', name: 'Dholpur' },
  { state: 'Rajasthan', name: 'Dungarpur' },
  { state: 'Rajasthan', name: 'Hanumangarh' },
  { state: 'Rajasthan', name: 'Jaipur' },
  { state: 'Rajasthan', name: 'Jaisalmer' },
  { state: 'Rajasthan', name: 'Jalore' },
  { state: 'Rajasthan', name: 'Jhalawar' },
  { state: 'Rajasthan', name: 'Jhunjhunu' },
  { state: 'Rajasthan', name: 'Jodhpur' },
  { state: 'Rajasthan', name: 'Karauli' },
  { state: 'Rajasthan', name: 'Kota' },
  { state: 'Rajasthan', name: 'Nagaur' },
  { state: 'Rajasthan', name: 'Pali' },
  { state: 'Rajasthan', name: 'Pratapgarh' },
  { state: 'Rajasthan', name: 'Rajsamand' },
  { state: 'Rajasthan', name: 'Sawai Madhopur' },
  { state: 'Rajasthan', name: 'Sikar' },
  { state: 'Rajasthan', name: 'Sirohi' },
  { state: 'Rajasthan', name: 'Sri Ganganagar' },
  { state: 'Rajasthan', name: 'Tonk' },
  { state: 'Rajasthan', name: 'Udaipur' },
  { state: 'Sikkim', name: 'East Sikkim' },
  { state: 'Sikkim', name: 'North Sikkim' },
  { state: 'Sikkim', name: 'South Sikkim' },
  { state: 'Sikkim', name: 'West Sikkim' },
  { state: 'Tamil Nadu', name: 'Ariyalur' },
  { state: 'Tamil Nadu', name: 'Chengalpattu' },
  { state: 'Tamil Nadu', name: 'Chennai' },
  { state: 'Tamil Nadu', name: 'Coimbatore' },
  { state: 'Tamil Nadu', name: 'Cuddalore' },
  { state: 'Tamil Nadu', name: 'Dharmapuri' },
  { state: 'Tamil Nadu', name: 'Dindigul' },
  { state: 'Tamil Nadu', name: 'Erode' },
  { state: 'Tamil Nadu', name: 'Kallakurichi' },
  { state: 'Tamil Nadu', name: 'Kanchipuram' },
  { state: 'Tamil Nadu', name: 'Kanyakumari' },
  { state: 'Tamil Nadu', name: 'Karur' },
  { state: 'Tamil Nadu', name: 'Krishnagiri' },
  { state: 'Tamil Nadu', name: 'Madurai' },
  { state: 'Tamil Nadu', name: 'Nagapattinam' },
  { state: 'Tamil Nadu', name: 'Namakkal' },
  { state: 'Tamil Nadu', name: 'Nilgiris' },
  { state: 'Tamil Nadu', name: 'Perambalur' },
  { state: 'Tamil Nadu', name: 'Pudukkottai' },
  { state: 'Tamil Nadu', name: 'Ramanathapuram' },
  { state: 'Tamil Nadu', name: 'Salem' },
  { state: 'Tamil Nadu', name: 'Sivaganga' },
  { state: 'Tamil Nadu', name: 'Tenkasi' },
  { state: 'Tamil Nadu', name: 'Thanjavur' },
  { state: 'Tamil Nadu', name: 'Theni' },
  { state: 'Tamil Nadu', name: 'Tiruchirappalli' },
  { state: 'Tamil Nadu', name: 'Tirunelveli' },
  { state: 'Tamil Nadu', name: 'Tirupathur' },
  { state: 'Tamil Nadu', name: 'Tiruppur' },
  { state: 'Tamil Nadu', name: 'Tiruvallur' },
  { state: 'Tamil Nadu', name: 'Tiruvannamalai' },
  { state: 'Tamil Nadu', name: 'Tiruvarur' },
  { state: 'Tamil Nadu', name: 'Vellore' },
  { state: 'Tamil Nadu', name: 'Viluppuram' },
  { state: 'Tamil Nadu', name: 'Virudhunagar' },
  { state: 'Telangana', name: 'Adilabad' },
  { state: 'Telangana', name: 'Bhadradri Kothagudem' },
  { state: 'Telangana', name: 'Hyderabad' },
  { state: 'Telangana', name: 'Jagtial' },
  { state: 'Telangana', name: 'Jangaon' },
  { state: 'Telangana', name: 'Jayashankar Bhupalapally' },
  { state: 'Telangana', name: 'Jogulamba Gadwal' },
  { state: 'Telangana', name: 'Kamareddy' },
  { state: 'Telangana', name: 'Karimnagar' },
  { state: 'Telangana', name: 'Khammam' },
  { state: 'Telangana', name: 'Kumuram Bheem' },
  { state: 'Telangana', name: 'Mahabubabad' },
  { state: 'Telangana', name: 'Mahabubnagar' },
  { state: 'Telangana', name: 'Mancherial' },
  { state: 'Telangana', name: 'Medak' },
  { state: 'Telangana', name: 'Medchal-Malkajgiri' },
  { state: 'Telangana', name: 'Mulugu' },
  { state: 'Telangana', name: 'Nagarkurnool' },
  { state: 'Telangana', name: 'Nalgonda' },
  { state: 'Telangana', name: 'Narayanpet' },
  { state: 'Telangana', name: 'Nirmal' },
  { state: 'Telangana', name: 'Nizamabad' },
  { state: 'Telangana', name: 'Peddapalli' },
  { state: 'Telangana', name: 'Rajanna Sircilla' },
  { state: 'Telangana', name: 'Rangareddy' },
  { state: 'Telangana', name: 'Sangareddy' },
  { state: 'Telangana', name: 'Siddipet' },
  { state: 'Telangana', name: 'Suryapet' },
  { state: 'Telangana', name: 'Vikarabad' },
  { state: 'Telangana', name: 'Wanaparthy' },
  { state: 'Telangana', name: 'Warangal Rural' },
  { state: 'Telangana', name: 'Warangal Urban' },
  { state: 'Telangana', name: 'Yadadri Bhuvanagiri' },
  { state: 'Tripura', name: 'Dhalai' },
  { state: 'Tripura', name: 'Gomati' },
  { state: 'Tripura', name: 'Khowai' },
  { state: 'Tripura', name: 'North Tripura' },
  { state: 'Tripura', name: 'Sepahijala' },
  { state: 'Tripura', name: 'South Tripura' },
  { state: 'Tripura', name: 'Unakoti' },
  { state: 'Tripura', name: 'West Tripura' },
  { state: 'Uttar Pradesh', name: 'Agra' },
  { state: 'Uttar Pradesh', name: 'Aligarh' },
  { state: 'Uttar Pradesh', name: 'Ambedkar Nagar' },
  { state: 'Uttar Pradesh', name: 'Amethi' },
  { state: 'Uttar Pradesh', name: 'Amroha' },
  { state: 'Uttar Pradesh', name: 'Auraiya' },
  { state: 'Uttar Pradesh', name: 'Ayodhya' },
  { state: 'Uttar Pradesh', name: 'Azamgarh' },
  { state: 'Uttar Pradesh', name: 'Badaun' },
  { state: 'Uttar Pradesh', name: 'Baghpat' },
  { state: 'Uttar Pradesh', name: 'Bahraich' },
  { state: 'Uttar Pradesh', name: 'Balrampur' },
  { state: 'Uttar Pradesh', name: 'Banda' },
  { state: 'Uttar Pradesh', name: 'Barabanki' },
  { state: 'Uttar Pradesh', name: 'Bareilly' },
  { state: 'Uttar Pradesh', name: 'Basti' },
  { state: 'Uttar Pradesh', name: 'Bhadohi' },
  { state: 'Uttar Pradesh', name: 'Bijnor' },
  { state: 'Uttar Pradesh', name: 'Budaun' },
  { state: 'Uttar Pradesh', name: 'Bulandshahr' },
  { state: 'Uttar Pradesh', name: 'Chandauli' },
  { state: 'Uttar Pradesh', name: 'Chitrakoot' },
  { state: 'Uttar Pradesh', name: 'Deoria' },
  { state: 'Uttar Pradesh', name: 'Etah' },
  { state: 'Uttar Pradesh', name: 'Etawah' },
  { state: 'Uttar Pradesh', name: 'Farrukhabad' },
  { state: 'Uttar Pradesh', name: 'Fatehpur' },
  { state: 'Uttar Pradesh', name: 'Firozabad' },
  { state: 'Uttar Pradesh', name: 'Gautam Buddh Nagar' },
  { state: 'Uttar Pradesh', name: 'Ghaziabad' },
  { state: 'Uttar Pradesh', name: 'Ghazipur' },
  { state: 'Uttar Pradesh', name: 'Gonda' },
  { state: 'Uttar Pradesh', name: 'Gorakhpur' },
  { state: 'Uttar Pradesh', name: 'Hamirpur' },
  { state: 'Uttar Pradesh', name: 'Hapur' },
  { state: 'Uttar Pradesh', name: 'Hardoi' },
  { state: 'Uttar Pradesh', name: 'Hathras' },
  { state: 'Uttar Pradesh', name: 'Jalaun' },
  { state: 'Uttar Pradesh', name: 'Jaunpur' },
  { state: 'Uttar Pradesh', name: 'Jhansi' },
  { state: 'Uttar Pradesh', name: 'Kannauj' },
  { state: 'Uttar Pradesh', name: 'Kanpur Dehat' },
  { state: 'Uttar Pradesh', name: 'Kanpur Nagar' },
  { state: 'Uttar Pradesh', name: 'Kasganj' },
  { state: 'Uttar Pradesh', name: 'Kaushambi' },
  { state: 'Uttar Pradesh', name: 'Kushinagar' },
  { state: 'Uttar Pradesh', name: 'Lakhimpur Kheri' },
  { state: 'Uttar Pradesh', name: 'Lalitpur' },
  { state: 'Uttar Pradesh', name: 'Lucknow' },
  { state: 'Uttar Pradesh', name: 'Maharajganj' },
  { state: 'Uttar Pradesh', name: 'Mahoba' },
  { state: 'Uttar Pradesh', name: 'Mainpuri' },
  { state: 'Uttar Pradesh', name: 'Mathura' },
  { state: 'Uttar Pradesh', name: 'Mau' },
  { state: 'Uttar Pradesh', name: 'Meerut' },
  { state: 'Uttar Pradesh', name: 'Mirzapur' },
  { state: 'Uttar Pradesh', name: 'Moradabad' },
  { state: 'Uttar Pradesh', name: 'Muzaffarnagar' },
  { state: 'Uttar Pradesh', name: 'Pilibhit' },
  { state: 'Uttar Pradesh', name: 'Pratapgarh' },
  { state: 'Uttar Pradesh', name: 'Prayagraj' },
  { state: 'Uttar Pradesh', name: 'Raebareli' },
  { state: 'Uttar Pradesh', name: 'Rampur' },
  { state: 'Uttar Pradesh', name: 'Saharanpur' },
  { state: 'Uttar Pradesh', name: 'Sambhal' },
  { state: 'Uttar Pradesh', name: 'Sant Kabir Nagar' },
  { state: 'Uttar Pradesh', name: 'Shahjahanpur' },
  { state: 'Uttar Pradesh', name: 'Shamli' },
  { state: 'Uttar Pradesh', name: 'Shravasti' },
  { state: 'Uttar Pradesh', name: 'Siddharthnagar' },
  { state: 'Uttar Pradesh', name: 'Sitapur' },
  { state: 'Uttar Pradesh', name: 'Sonbhadra' },
  { state: 'Uttar Pradesh', name: 'Sultanpur' },
  { state: 'Uttar Pradesh', name: 'Unnao' },
  { state: 'Uttar Pradesh', name: 'Varanasi' },
  { state: 'Uttarakhand', name: 'Almora' },
  { state: 'Uttarakhand', name: 'Bageshwar' },
  { state: 'Uttarakhand', name: 'Chamoli' },
  { state: 'Uttarakhand', name: 'Champawat' },
  { state: 'Uttarakhand', name: 'Dehradun' },
  { state: 'Uttarakhand', name: 'Haridwar' },
  { state: 'Uttarakhand', name: 'Nainital' },
  { state: 'Uttarakhand', name: 'Pauri Garhwal' },
  { state: 'Uttarakhand', name: 'Pithoragarh' },
  { state: 'Uttarakhand', name: 'Rudraprayag' },
  { state: 'Uttarakhand', name: 'Tehri Garhwal' },
  { state: 'Uttarakhand', name: 'Udham Singh Nagar' },
  { state: 'Uttarakhand', name: 'Uttarkashi' },
  { state: 'West Bengal', name: 'Alipurduar' },
  { state: 'West Bengal', name: 'Bankura' },
  { state: 'West Bengal', name: 'Birbhum' },
  { state: 'West Bengal', name: 'Cooch Behar' },
  { state: 'West Bengal', name: 'Dakshin Dinajpur' },
  { state: 'West Bengal', name: 'Darjeeling' },
  { state: 'West Bengal', name: 'Hooghly' },
  { state: 'West Bengal', name: 'Howrah' },
  { state: 'West Bengal', name: 'Jalpaiguri' },
  { state: 'West Bengal', name: 'Jhargram' },
  { state: 'West Bengal', name: 'Kalimpong' },
  { state: 'West Bengal', name: 'Kolkata' },
  { state: 'West Bengal', name: 'Malda' },
  { state: 'West Bengal', name: 'Murshidabad' },
  { state: 'West Bengal', name: 'Nadia' },
  { state: 'West Bengal', name: 'North 24 Parganas' },
  { state: 'West Bengal', name: 'Paschim Bardhaman' },
  { state: 'West Bengal', name: 'Paschim Medinipur' },
  { state: 'West Bengal', name: 'Purba Bardhaman' },
  { state: 'West Bengal', name: 'Purba Medinipur' },
  { state: 'West Bengal', name: 'Purulia' },
  { state: 'West Bengal', name: 'South 24 Parganas' },
  { state: 'West Bengal', name: 'Uttar Dinajpur' }
];
const parseLSQData = (data) => {
  if (data?.ProspectID && data?.Phone.match(/^(?:\+91-?)?\d{10}$/)) {
    const validPhone = (val) => {
      const regex = /^(?:\+91-?)?(\d{10})$/;

      if (regex.test(val)) {
        const match = val.match(regex);
        return `+91-${match[1]}`;
      } else {
        // Handle invalid phone number here, or return the original input
        return '';
      }
    };
    const placementAssistanceRequired = Boolean(
      data?.mx_Interested_in_Placement_Assistance === '1' ||
        data?.mx_Interested_in_Placement_Assistance === 1
    );

    return {
      prospectId: data?.ProspectID,
      firstName: data?.FirstName || '',
      lastName: data?.LastName || '',
      gender: data?.mx_Gender || '',
      description: '',
      emailAddress: data?.EmailAddress || '',
      phone: validPhone(data?.Phone) || '',
      whatsappNumber: data?.mx_WhatsApp_Number || '',
      currentAddress: '',
      city: '',
      state: '',
      pin: '',
      dateOfBirth: data?.mx_Date_of_Birth
        ? formatDate(data?.mx_Date_of_Birth, 'M/d/yy')
        : '',
      latitude: '',
      longitude: '',
      profileCompletion: '',
      educationalQualifications: [
        {
          level: 'Xth',
          type: 'TENTH',
          institutionName: data?.mx_Class_10_Board || '',
          collegeName: '',
          yearOfPassing: '',
          specialization: '',
          marks: parseInteger(data?.mx_10th_Std_Marks) || ''
        },
        {
          level: 'XIIth',
          type: 'TWELFTH',
          institutionName: data?.mx_Class_12_Board || '',
          collegeName: '',
          yearOfPassing: '',
          specialization: '',
          marks: parseInteger(data?.mx_12_Std_Marks) || ''
        },
        {
          level: data?.mx_Undergraduate_Degree || '',
          type: 'PG',
          institutionName: data?.mx_UG_University_Name || '',
          collegeName: '',
          yearOfPassing: data?.mx_UG_Year_of_Passing || '',
          specialization: data?.mx_UnderGraduate_Specialization_ || '',
          marks: parseInteger(data?.mx_UG_CGPA) || ''
        },
        {
          level: data?.mx_Post_Graduate_Degree || '',
          type: 'UG',
          institutionName: data?.mx_Gr_University_Name || '',
          collegeName: '',
          yearOfPassing: data?.mx_Gr_Year_of_Passing || '',
          specialization: data?.mx_Post_Graduate_Specialization_ || '',
          marks: parseInteger(data?.mx_Gr_CGPA) || ''
        }
      ],
      workExperience: {
        industry: data?.mx_Current_Industry || '',
        currentOrganization: data?.mx_Current_Company_Name || '',
        currentDesignation: data?.mx_Current_designation || '',
        totalWorkExperience: data?.mx_Years_of_Work_Experience || null,
        salaryPerAnnum: null,
        resumeLocation: null,
        videoResumeLocation: null,
        socialProfiles: []
      },
      skillsProficiency: [],
      youAndHeroVired: {
        programName: null,
        isPlacementPolicyAccepted: !(
          data?.mx_Placement_policy?.length || data?.mx_Placement_policy < 0
        )
          ? null
          : data?.mx_Placement_policy === '1' || data?.mx_Placement_policy === 1
          ? 'True'
          : 'False',
        desiredOutcome: null,
        noticePeriod: null,
        placementAssistanceRequired: !(
          data?.mx_Interested_in_Placement_Assistance?.length ||
          data?.mx_Interested_in_Placement_Assistance < 0
        )
          ? null
          : data?.mx_Interested_in_Placement_Assistance === '1' ||
            data?.mx_Interested_in_Placement_Assistance === 1
          ? 'Yes'
          : 'No',
        openToInternships: !(
          data?.mx_Are_you_open_to_internships?.length ||
          data?.mx_Are_you_open_to_internships < 0
        )
          ? null
          : data?.mx_Are_you_open_to_internships === '1' ||
            data?.mx_Are_you_open_to_internships === 1
          ? 'Yes'
          : 'No',
        requiredPlacementAssistantStage: null,
        preferredJobLocations: !placementAssistanceRequired
          ? []
          : data?.mx_Preferred_Role?.length
          ? data?.mx_Preferred_Role?.split(',')
          : [],
        preferredJobRoles: !placementAssistanceRequired
          ? []
          : data?.mx_Preferred_Job_Location_1?.length ||
            data?.mx_Preferred_Job_Location_2?.length
          ? [
              data?.mx_Preferred_Job_Location_1 || '',
              data?.mx_Preferred_Job_Location_2 || ''
            ]
          : []
      },
      source: 'Vlearn'
    };
  }
  return {};
};
const profileWidgetDefault = {
  prospectId: '',
  firstName: '',
  lastName: '',
  gender: '',
  description: '',
  emailAddress: '',
  phone: '',
  whatsappNumber: '',
  currentAddress: '',
  city: '',
  state: '',
  pin: '',
  dateOfBirth: '',
  latitude: '',
  longitude: '',
  profileCompletion: 0,
  educationalQualifications: [
    {
      level: 'Xth',
      type: 'TENTH',
      institutionName: '',
      collegeName: '',
      yearOfPassing: '',
      specialization: '',
      marks: ''
    },
    {
      level: 'XIIth',
      type: 'TWELFTH',
      institutionName: '',
      collegeName: '',
      yearOfPassing: '',
      specialization: '',
      marks: ''
    },
    {
      level: 'Undergraduate Degree',
      type: 'UG',
      institutionName: '',
      collegeName: '',
      yearOfPassing: '',
      specialization: '',
      marks: ''
    },
    {
      level: 'Post Undergraduate Degree',
      type: 'PG',
      institutionName: '',
      collegeName: '',
      yearOfPassing: '',
      specialization: '',
      marks: ''
    }
  ],
  workExperience: {
    industry: '',
    currentOrganization: '',
    currentDesignation: '',
    totalWorkExperience: '',
    salaryPerAnnum: '',
    resumeLocation: '',
    videoResumeLocation: '',
    socialProfiles: []
  },
  skillsProficiency: [],
  youAndHeroVired: {
    programName: '',
    isPlacementPolicyAccepted: '',
    desiredOutcome: '',
    noticePeriod: '',
    placementAssistanceRequired: '',
    openToInternships: '',
    requiredPlacementAssistantStage: '',
    preferredJobLocations: [],
    preferredJobRoles: []
  },
  source: 'Vlearn'
};

const universityNames = [
  'Others',
  '“Kaushalya” the Skill University',
  'A.K.S. University',
  'A.P.G.(Alakh Prakash Goyal) Shimla University',
  'A.P.J. Abdul Kalam Technological University',
  'AAFT University of Media and Arts',
  'Abhilashi University',
  'Academy of Maritime Education and Training',
  'Acharya N.G. Ranga Agricultural University',
  'Acharya Nagarjuna University',
  'Adamas University',
  'Adesh University',
  'Adichunchanagiri University',
  'Adikavi Nannaya University',
  'Agriculture University',
  'Ahmedabad University',
  'AIPH University',
  'AISECT University',
  'Ajeenkya D.Y. Patil University',
  'Akal University',
  'Al-Falah University',
  'Al-Karim University',
  'Alagappa University',
  'Aliah University',
  'Aligarh Muslim University',
  'Alipurduar University',
  'Alliance University',
  'Amity University',
  'Amity University Rajasthan',
  'Amrita Vishwa Vidyapeetham',
  'Anand Agricultural Univerisity',
  'Anant National University',
  'Andhra Kesari University',
  'Andhra University',
  'Anna University',
  'Annamalai University',
  'Anurag University',
  'Apeejay Stya University',
  'Apex Professional University',
  'Apex University',
  'ARKA Jain University',
  'ArniUniversity',
  'Arunachal University of Studies',
  'Arunodaya University',
  'Aryabhatta knowledge University',
  'ASBM University',
  'Ashoka University',
  'Asian International University',
  'Assam Agricultural University',
  'Assam Don Bosco University',
  'Assam Down Town University',
  'Assam Rajiv Gandhi University of Co-operative Management',
  'Assam Science & Technology University',
  'Assam University',
  'Assam Women’s University',
  'Atal Bihari Vajpai Hindi Vishwavidyalaya',
  'Atal Bihari Vajpayee Medical University',
  'Atal Bihari Vajpayee Vishwavidyalaya (Formerly Bilaspur Vishwavidyalaya)',
  'Atal Medical and Research University',
  'ATLAS SKILLTECH UNIVERSITY',
  'Atmiya University',
  'Atria University',
  'AURO University of Hospitality and Management',
  'Avantika University',
  'Avinashilingam Institute for Home Science & Higher Education for Women',
  'Awadesh Pratap Singh University',
  'Ayush and Health Sciences University of Chhattisgarh',
  'Azim Premji University',
  'B.L.D.E. Bijapur',
  'B.S. Abdur Rahman Crescent Institute of Science and Technology',
  'Baba Farid University of Health Sciences',
  'Baba Ghulam Shah Badshah University',
  'Baba Mast Nath University',
  'Babasaheb Bhimrao Ambedkar Bihar University',
  'Babasaheb Bhimrao Ambedkar University',
  'Babu Banarasi Das University',
  'Baddi University of Emerging Sciences & Technology',
  'Bahra University',
  'Banaras Hindu University',
  'Banasthali Vidyapith',
  'Banda University of Agriculture & Technology',
  'Bangalore University',
  'Bankura University',
  'Bareilly International University',
  'Barkatullah University',
  'Bengaluru Central University',
  'Bengaluru Dr. B.R. Ambedkar School of Economics University',
  'Bengaluru North University',
  'Bennett University',
  'Berhampur University',
  'Bhabha University',
  'Bhagat Phool Singh Mahila Vishwavidyalaya',
  'Bhagwan Mahavir University',
  'Bhagwant Global University',
  'BhagwantUniversity',
  'Bhaikaka University',
  'Bhakta Kavi Narsinh Mehta University',
  'Bharath Institute of Higher Education & Research',
  'Bharathiar University',
  'Bharathidasan University',
  'Bharati Vidyapeeth',
  'Bharatiya Engineering Science and Technology Innovation University',
  'Bharti Vishwavidyalaya',
  'Bhartiya Skill Development University',
  'Bhatkhande Music Institute',
  'Bhattadev University',
  'Bhupal Nobles University',
  'Bhupendra Narayan Mandal University',
  'Bidhan Chandra Krishi Vishwavidyalaya',
  'Bihar Agricultural University',
  'Bihar Animal Sciences University',
  'Biju Patnaik University of Technology',
  'Bikaner Technical University',
  'Binod Bihar Mahto Koylanchal University',
  'Bir Tikendrajit University',
  'Birangana Sati Sadhani Rajyik Vishwavidyalaya',
  'Birla Global University',
  'Birla Institute of Technology',
  'Birla Institute of Technology & Science',
  'Birsa Agricultural University',
  'Birsa Munda Tribal University',
  'Biswa Bangla Biswabidyalay',
  'BML Munjal University',
  'Bodoland University',
  'Brainware University',
  'Bundelkhand University',
  'C.T. University',
  'C.U. Shah University',
  'C.V.Raman Global University',
  'Capital University',
  'Career Point University',
  'Central Agricultural University',
  'Central Institute of Buddhist Studies',
  'Central Institute of Fisheries Education',
  'Central Institute of Higher Tibetan Studies',
  'Central Institute of Technology(CIT)',
  'Central Tribal University of Andhra Pradesh',
  'Central University of Andhra Pradesh',
  'Central University of Gujarat',
  'Central University of Haryana',
  'Central University of Himachal Pradesh',
  'Central University of Jammu',
  'Central University of Jharkhand',
  'Central University of Karnataka',
  'Central University of Kashmir',
  'Central University of Kerala',
  'Central University of Odisha',
  'Central University of Punjab',
  'Central University of Rajasthan',
  'Central University of South Bihar',
  'Central University of Tamil Nadu',
  'Centre for Environmental Planning and Technology University',
  'Centurion University of Technology and Management',
  'Chaitanya (Deemed to be University)',
  'Chanakya National Law University',
  'Chanakya University',
  'Chandigarh University',
  'Chandra Shekhar Azad University of Agriculture & Technology',
  'Charotar University of Science & Technology',
  'Chatrapati Shahuji Maharaj Kanpur University',
  'Chaudhary Bansi Lal University',
  'Chaudhary Devi Lal University',
  'Chaudhary Ranbir Singh University',
  'Chaudhary Sarwan Kumar Himachal Pradesh Krishi Vishvavidyalaya',
  'Chennai Mathematical Institute',
  'Chettinad Academy of Research and Education (CARE)',
  'Chhatrapati Shivaji Maharaj University',
  'Chhattisgarh Kamdhenu Vishwavidyalaya',
  'Chhattisgarh Swami Vivekanand Technical University',
  'Children’s University',
  'Chinmaya Vishwavidyapeeth',
  'Chitkara University',
  'Choudhary Charan Singh Haryana Agricultural University',
  'Choudhary Charan Singh University',
  'Christ',
  'Cluster University',
  'Cluster University of Jammu',
  'Cluster University of Srinagar',
  'CMJ University',
  'CMR University',
  'Cochin University of Science & Technology',
  'Cooch Behar Panchanan Barma University',
  'Cotton College State University',
  'D.A.V. University',
  'D.Y. Patil Agriculture and Technical University',
  'D.Y. Patil Educational Society',
  'D.Y. Patil International University',
  'D.Y.Patil University',
  'Dakshin Dinajpur University',
  'Damodaram Sanjivayya National Law University',
  'Darshan University',
  'Datta Meghe Institute of Medical Sciences',
  'Davangere University',
  'Dayalbagh Educational Institute',
  'Dayananda Sagar University',
  'Deccan College of Post-Graduate & Research Institute',
  'Deen Bandhu Chhotu Ram University of Science & Technology',
  'Deen Dayal Upadhyay Gorakhpur University',
  'Defence Institute of Advanced Technology',
  'Delhi Pharmaceutical Sciences & Research University',
  'Delhi Skill and Entrepreneurship University',
  'Delhi Sports University',
  'Delhi Teachers University',
  'Delhi Technological University',
  'Desh Bhagat University',
  'Dev Bhoomi Uttrakhand University',
  'Dev Sanskriti Vishwavidyalaya',
  'Dev Sanskrity Vishwavidyalaya',
  'Devi Ahilya Vishwavidyalaya',
  'Dhanalakshmi Srinivasan University',
  'Dhanamanjuri University',
  'Dharmashastra National Law University',
  'Dharmsinh Desai University',
  'Dhirubhai Ambani Institute of Information and Communication Technology',
  'Diamond Harbour Women’s University',
  'Dibrugarh University',
  'DIT University',
  'Doon University',
  'Dr Ram Manohar Lohia Awadh University',
  'Dr. A.P.J. Abdul Kalam Technical University (Formerly Uttar Pradesh Technical University) Sector- 11',
  'Dr. A.P.J. Abdul Kalam University',
  'Dr. Abdul Haq Urdu University',
  'Dr. B.R. Ambedkar National Law University (Formarly National Law University Haryana)',
  'Dr. B.R. Ambedkar Open University',
  'Dr. B.R. Ambedkar University',
  'Dr. B.R. Ambedkar University (formerly Bharat Ratna Dr. B.R. Ambedkar University)',
  'Dr. B.R. Ambedkar University of Social Sciences',
  'Dr. Babasaheb Ambedkar Marathwada University',
  'Dr. Babasaheb Ambedkar Open University',
  'Dr. Babasaheb Ambedkar Technological University',
  'Dr. Bhimrao Ambedkar Law University',
  'Dr. C.V. Raman University',
  'Dr. D.Y. Patil Vidyapeeth',
  'Dr. Harisingh Gour Vishwavidyalaya',
  'Dr. Homi Bhabha State University',
  'Dr. K.N. Modi University',
  'Dr. M.G.R. Educational and Research Institute',
  'Dr. N.T.R. University of Health Sciences (Formerly Andhra Pradesh University of Health Sciences)',
  'Dr. Punjabrao Deshmukh Krishi Vidyapeeth',
  'Dr. Ram Manohar Lohia Institute of Medical Sciences',
  'Dr. Ram Manohar Lohiya National Law University',
  'Dr. Shyama Prasad Mukherjee University',
  'Dr. Vishwanath Karad MIT World Peace University',
  'Dr. Y.S.Parmar University of Horticulture & Forestry',
  'Dr. Y.S.R Architecture and Fine Arts University',
  'Dr. Y.S.R. Horticultural University',
  'Dravidian University',
  'Drs. Kiran & Pallavi Patel Global University (KPGU)',
  'Durg Vishwavidyalaya',
  'Eklavya University',
  'Era University',
  'EternalUniversity',
  'F.S. University',
  'Fakir Mohan University',
  'Flame University',
  'Forest Research Institute',
  'G.B. Pant University of Agriculture and Technology',
  'G.D. Goenka University',
  'G.H. Raisoni University',
  'G.H.Raisoni University',
  'G.L.A. University',
  'G.L.S. University',
  'Galgotias University',
  'Gandhi Institute of Engineering & Technology University',
  'Gandhi Institute of Technology and Management (GITAM)',
  'Gandhigram Rural Institute',
  'Gangadhar Meher University',
  'Ganpat University',
  'Garden City University',
  'Gauhati University',
  'Gautam Buddha University',
  'Geeta University',
  'Geetanjali University',
  'GNA University',
  'Goa University',
  'Gokhale Institute of Politics & Economics',
  'Gokul Global University',
  'Gondwana University',
  'Gopal Narayan Singh University',
  'Govind Guru Tribal University',
  'Graphic Era',
  'Graphic Era Hill University (Formerly Graphic Era Parvatiya Vishwavidyalaya)',
  'GSFC University',
  'Gujarat Ayurveda University',
  'Gujarat Biotechnology University',
  'Gujarat Maritime University',
  'Gujarat National Law University',
  'Gujarat Technlogical University',
  'Gujarat University',
  'Gujarat University of Transplantation Sciences',
  'Gujarat Vidyapith',
  'Gulbarga University',
  'Guru Angad Dev Veterinary & Animal Sciences University',
  'Guru Ghasidas Vishwavidyalaya',
  'Guru Gobind Singh Indraprastha Vishwavidyalaya',
  'Guru Jambeshwar University of Science and Technology',
  'Guru Kashi University',
  'Guru Nanak Dev University',
  'Guru Ravidas Ayurved University',
  'Gurugram University',
  'Gurukul Kangri Vishvidayala',
  'Harcourt Butler Technical University',
  'Harichand Guruchand University',
  'Haridev Joshi University of Journalism and Mass Communication',
  'Hemchandracharya North Gujarat University',
  'Hemvati Nandan Bahuguna Garhwal University',
  'Hemwati Nandan Bahuguna Medical Education University',
  'Hidayatullah National Law University',
  'Himachal Pradesh National Law University',
  'Himachal Pradesh Technical University',
  'Himachal Pradesh University',
  'Himalayan Garhwal University',
  'Himalayan University',
  'Himalayiya University',
  'Himgiri Zee University',
  'Hindi University',
  'Hindustan Institute of Technology and Science (HITS)',
  'Homi Bhabha National Institute',
  'HomoeopathyUniversity',
  'Hyderabad (SIND) National Collegiate University',
  'I.E.C. (India Education Centre) University',
  'I.E.S University',
  'ICFAI Foundation for Higher Education',
  'ICFAI University',
  'IFTM University',
  'IIHMR University',
  'IILM University',
  'IIMT University',
  'IIS',
  'IMS Unison University',
  'Indian Agricultural Research Institute',
  'Indian Association for the Cultivation of Science (IACS)',
  'Indian Institute of Foreign Trade',
  'Indian Institute of Public Health',
  'Indian Institute of Science',
  'Indian Institute of Space Science and Technology',
  'Indian Institute of Teacher Education',
  'Indian Law Institute',
  'Indian Maritime University',
  'Indian Veterinary Research Institute',
  'Indira Gandhi Delhi Technical University for Women',
  'Indira Gandhi Institute of Development Research',
  'Indira Gandhi Krishi Vishwavidyalaya',
  'Indira Gandhi National Open University',
  'Indira Gandhi University',
  'Indira Kala Sangeet Vishwavidyalaya',
  'Indraprastha Institute of Information Technology',
  'Indrashil University',
  'Indus University',
  'IndusInternationalUniversity',
  'Institute of Advanced Research',
  'Institute of Advanced Studies in Education',
  'Institute of Chartered Financial Analysts of India',
  'Institute of Chartered Financial Analysts of India (ICFAI)',
  'Institute of Chemical Technology',
  'Institute of Infrastructure Technology Research and Management',
  'Institute of Liver and Biliary Sciences (ILBS)',
  'Integral University',
  'International Institute for Population Sciences',
  'International Institute of Information Technology',
  'International Sports Univeristy',
  'Invertis University',
  'ISBM University',
  'Islamic University of Science & Technology University',
  'ITM (SLS) Baroda University',
  'ITM University',
  'ITM-Vocational University',
  'J.C Bose University of Science & Technology (Formerly YMCA University of Science & Technology)',
  'J.E.C.R.C. University',
  'J.G.University',
  'J.K. Lakshmipat University',
  'J.S. University',
  'Jadavpur University',
  'Jagadguru Ramanandacharya Rajasthan Sanskrit University',
  'Jagadguru Rambhadracharya Handicapped University',
  'Jagan Nath University',
  'Jagat Guru Nanak Dev Punjab State Open University',
  'Jagran Lakecity University',
  'Jai Narain Vyas University',
  'Jai Prakash Vishwavidyalaya',
  'Jain',
  'Jain Vishva Bharati Institute',
  'Jaipur National University',
  'Jamia Hamdard',
  'Jamia Mallia Islamia University',
  'Janardan Rai Nagar Rajasthan Vidyapeeth',
  'Jannayak Chandrashekhar University',
  'Jawaharlal Nehru Architecture and Fine Arts University',
  'Jawaharlal Nehru Centre for Advanced Scientific Research',
  'Jawaharlal Nehru Krishi Vishwavidyalaya',
  'Jawaharlal Nehru Technological University',
  'Jawaharlal Nehru Technological University - Gurajada',
  'Jawaharlal Nehru University',
  'Jayoti Vidyapeeth Women’s University',
  'Jaypee Institute of Information Technology',
  'Jaypee University',
  'Jaypee University of Engineering & Technology',
  'Jaypee University of Information Technology',
  'Jeppiaar University',
  'Jharkhand Rai University',
  'Jharkhand Raksha Shakti University',
  'Jharkhand University of Technology',
  'JIS University',
  'Jiwaji University',
  'Jodhpur National University',
  'JSS Academy of Higher Education & Research',
  'JSS Science & Technology University',
  'Junagarh Agricultural University',
  'K.K. University',
  'K.K.Modi University',
  'K.R. Mangalam University',
  'Kadi Sarva Vishwavidyalaya',
  'Kakatiya University',
  'Kalahandi University',
  'Kalasalingam Academy of Research and Higher Education',
  'Kalinga Institute of Industrial Technology',
  'Kalinga Institute of Social Sciences',
  'Kalinga University',
  'Kaloji Narayan Rao University of Health Sciences',
  'Kamdhenu University',
  'Kameshwara Singh Darbhanga Sanskrit Vishwavidyalaya',
  'Kannada University',
  'Kannur University',
  'Kanyashree University',
  'Karnataka Folklore University',
  'Karnataka Janapada Vishwavidyalaya',
  'Karnataka Samskrit University',
  'Karnataka State Law University',
  'Karnataka State Open University',
  'Karnataka State Rural Developoment and Panchayat Raj University',
  'Karnataka State Women University',
  'Karnataka University',
  'Karnataka Veterinary',
  'Karnavati University',
  'Karpagam Academy of Higher Education',
  'Karunya Institute of Technology and Sciences',
  'Kavayitri Bahinbai Chaudhari North Maharashtra University (Formarly North Maharashtra University) PB No. 80',
  'Kavi Kulguru Kalidas Sanskrit Vishwavidyalaya',
  'Kazi Nazrul University',
  'Kerala Agricultural University',
  'Kerala Kalamandalam',
  'Kerala University of Digital Sciences Innovation and Technology',
  'Kerala University of Fisheries & Ocean Studies',
  'Kerala University of Health Sciences',
  'Kerala Veterinary & Animal Sciences University',
  'Khaja Bandanawaz University',
  'Khallikote University',
  'Khangchendzonga State University (Formerly Sikkim State University)',
  'Khwaja Moinuddin Chishti Urdu',
  'King George Medical University',
  'KLE Academy of Higher Education and Research',
  'KLE Technological University',
  'Kolhan University',
  'Koneru Lakshmaiah Education Foundation',
  'Konkan Krishi Vidyapeeth',
  'Krantiguru Shyamji Krishna Verma Kachchh University',
  'KREA University',
  'Krishna Institute of Medical Sciences',
  'Krishna Kanta Handiqui State Open University (Formerly Krishna Kanta Handique State Open University)',
  'Krishna University',
  'Krishnaguru Adhyatmik Visvavidyalaya',
  'KSGH Music and Performing Arts University',
  'Kumar Bhaskar Varma Sanskrit & Ancient Studies University',
  'Kumaun University',
  'Kurukshetra University',
  'Kushabhau Thakre Patrakarita Avam Jansanchar Vishwavidyalaya',
  'Kuvempu University',
  'Lakshmibai National Institute of Physical Education',
  'Lakulish Yoga University',
  'Lala Lajpat Rai University of Veterinary & Animal Sciences',
  'Lalit Narayan Mithila University',
  'Lamrin Tech Skills University',
  'Lingaya’s Vidyapeeth',
  'LNCT University',
  'Lok Jagruti Kendra University',
  'Lords University',
  'Lovely Professional University',
  'M.P.Bhoj (open) University',
  'M.S. Ramaiah University of Applied Sciences',
  'M.V.N. University',
  'Madan Mohan Malviya University of Technology',
  'Madhabdev University',
  'Madhav University',
  'Madhusudan Law University',
  'Madhya Pradesh Medical Science University',
  'Madhyanchal Professional University',
  'Madurai Kamraj University',
  'MagadhUniversity',
  'Mahakaushal University',
  'Mahapurusha Srimanta Sankaradeva Viswavidyalaya',
  'Maharaj Vinayak Global University',
  'Maharaja Agrasen University',
  'Maharaja Bir Bikram University',
  'Maharaja Chhatrasal Bundelkhand Vishwavidyalaya',
  'Maharaja Ganga Singh University',
  'Maharaja Krishnakumarsinji Bhavnagar University',
  'Maharaja Ranjit Singh Punjab Technical University',
  'Maharaja Sayajirao University of Baroda',
  'Maharaja Sriram Chandra Bhanja Deo University (Formerly North',
  'Maharaja Surajmal Brij University',
  'Maharana Pratap Horticultural University',
  'Maharana Pratap University of Agriculture & Technology',
  'Maharani Cluster University',
  'Maharashtra Animal & Fishery Sciences University',
  'Maharashtra Institute of Technology University of Meghalaya (Formerly University of Technology & Management)',
  'Maharashtra Natinoal Law University',
  'Maharashtra National Law University',
  'Maharashtra University of Health Sciences',
  'Maharishi Arvind University',
  'Maharishi Balmiki Sanskrit University',
  'Maharishi Dayanand Saraswati University',
  'Maharishi Dayanand University',
  'Maharishi Mahesh Yogi Vedic Vishwavidyalaya',
  'Maharishi Markandeshwar',
  'Maharishi Markandeshwar University',
  'Maharishi Panini Sanskrit Evam Vedic Vishwavidyalaya',
  'Maharishi University of Information Technology',
  'Maharishi University of Management and Technology',
  'Mahatma Gandhi Antarrashtriya Hindi Vishwavidyalay',
  'Mahatma Gandhi Central University',
  'Mahatma Gandhi Chitrakoot Gramoday Vishwavidyalaya',
  'Mahatma Gandhi Kashi Vidyapeeth',
  'Mahatma Gandhi Udyanikee Evam Vanikee Vishwavidyalaya',
  'Mahatma Gandhi University',
  'Mahatma Gandhi University (Formerly Purba Medinipur University)',
  'Mahatma Gandhi University of Medical Sciences & Technology',
  'Mahatma Jyoti Rao Phule University',
  'Mahatma Jyotiba Phule Rohilkhand University',
  'Mahatma Phule Krishi Vidyapeeth',
  'Mahayogi Gorakhnath University Gorakhpur',
  'Mahindra University Bahadurpaly(V)',
  'Majuli University of Culture',
  'Makhanlal Chaturvedi National University of Journalism and Communication',
  'Malla Reddy University',
  'Malwanchal University',
  'Manav Bharti University',
  'Manav Rachna University',
  'ManavRachna Internationa Institute of Research & Studies',
  'Mandsaur University',
  'Mandya University',
  'Mangalayatan University',
  'Mangalore University',
  'Manipal Academy of Higher Education',
  'Manipal University',
  'Manipur International University',
  'Manipur Technical University',
  'Manipur University',
  'Manipur University of Culture',
  'Manonmaniam Sundarnar University',
  'Mansarovar Global University',
  'Marathwada Agricultural University',
  'Martin Luther Christian University',
  'Marwadi University',
  'Mata Gujri University',
  'MATS University',
  'Maulana Abul Kalam Azad University of Technology',
  'Maulana Azad National Urdu University',
  'Maulana Azad University',
  'Maulana Mazharul Haque Arabic & Persian University',
  'Medhavi Skills University',
  'Medi-Caps University',
  'Meenakshi Academy of Higher Education and Research',
  'Mewar University',
  'MGM Institute of Health Sciences',
  'MGM University',
  'MIT Art Design & Technology University',
  'Mizoram University',
  'Mody University of Science and Technology',
  'Mohammad Ali Jauhar University',
  'Mohan Lal Sukhadia University',
  'Monad University',
  'Monark University',
  'Morarji Desai National Institute of Yoga',
  'Mother Teresa Women’s University',
  'Motherhood University',
  'Munger University',
  'Murshidabad University',
  'Nagaland University',
  'Nalanda Open University',
  'Nalanda University',
  'NALSAR University of Law',
  'Nanaji Deshmukh Pashu Chikitsa Vigyan Vishwavidyalaya',
  'Narendra Deo University of Agriculture & Technology',
  'Narsee Monjee Institute of Management Studies',
  'National Brain Research Centre',
  'National Dairy Research Institute',
  'National Institute of Ayurveda (NIA)',
  'National Institute of Educational Planning and Administration',
  'National Institute of Food Technology',
  'National Law Institute University',
  'National law School of India University',
  'National law University',
  'National Law University and Judicial Academy',
  'National Museum Institute of History of Art',
  'National Rail and Transportation Institute (NRTI)',
  'National Sports University',
  'National University of Advanced Legal Studies (NUALS) NUALS',
  'National University of Study & Research in Law',
  'Nava Nalanda Mahavihara',
  'Navrachana University',
  'Navsari Agricultural University',
  'Nehru Gram Bharati',
  'Netaji Subhas University',
  'Netaji Subhas University of Technology',
  'Netaji Subhash Open University',
  'NIILM University',
  'NIIT University',
  'Nilamber-Pitamber University',
  'Nims University Rajasthan',
  'Nirma University',
  'Nirwan University',
  'NITTE',
  'Nizam’s Institute of Medical Sciences',
  'Noida International University',
  'Noorul Islam Centre for Higher Education',
  'North East Adventist University',
  'North East Christian University',
  'North East Frontier Technical University',
  'North Eastern Hill University',
  'North Eastern Regional Institute of Science & Technology',
  'Nrupathunga University',
  'O.P. Jindal Global University',
  'O.P. Jindal University',
  'Odisha State Open University',
  'Odisha University of Technology and Research',
  'Om Sterling Global University',
  'OPJS University',
  'Oriental University',
  'Orissa University of Agriculture & Technology',
  'Osmania University',
  'P.K. University',
  'P.P. Savani University',
  'Pacific Academy of Higher Education & Research University',
  'Pacific Medical University',
  'Padmashree Dr. D.Y. Patil Vidyapeeth',
  'PalamuruUniversity',
  'Pandit Deendayal Energy University (Formerly Pandit Deendayal Petroleum University)',
  'Pandit Deendayal Upadhyaya Shekhawati University (Formerly Shekhawati University)',
  'Pandit Lakhmi Chand State University of Performing and Visual Arts (Formerly State University of Performing and Visual Arts)',
  'Pandit S.N. Shukla University',
  'Pandit Sundar Lal Sharma (Open) University Chhatisgarh',
  'Panjab University',
  'Parul University',
  'Patliputra University',
  'Patna University',
  'PDM University',
  'People’s University',
  'Periyar Maniammai Institute of Science & Technology (PMIST)',
  'Periyar University',
  'PES University',
  'Plaksha University Punjab',
  'Plastindia International University',
  'Pondicherry University',
  'Ponnaiyah Ramajayam Institute of Science & Technology (PRIST)',
  'Poornima University',
  'Potti Sreeramulu Telugu University',
  'Pragyan International University',
  'Pratap University',
  'Pravara Institute of Medical Sciences',
  'Presidency University',
  'Presidency University Dibbur & Ittagalpura Village',
  'Prof. Rajendra Singh (Rajju Bhaiya) University (Formerly Allahabad State University',
  'Professor Jayashankar Telangana State Agricultural University',
  'Pt. Ravishankar Shukla University',
  'Pt.Bhagwat Dayal Sharma University of Health Sciences',
  'Puducherry Technological University',
  'Punjab Agricultural University',
  'Punjab Engineering College',
  'Punjabi University',
  'Punyashlok Ahilyadevi Holkar Solapur University (formerly Solapur',
  'Purnea University',
  'Quantum University',
  'R.K. University',
  'R.N.B. Global University',
  'Rabindra Bharati University',
  'Rabindranath Tagore University',
  'Rabindranath Tagore University (Formerly AISECT University)Village –',
  'Radha Govind University',
  'Raffles University',
  'Rai Technology University',
  'Rai University',
  'Raichur University',
  'Raiganj University',
  'Raj Rishi Bhartrihari Matsya University',
  'Raja Mansingh Tomar Music & Arts University',
  'Raja Shankar Shah University (Formerly Chhindwara University)',
  'Rajasthan Agricultural University',
  'Rajasthan Ayurveda University',
  'Rajasthan ILD Skills University (RISU)',
  'Rajasthan Technical University',
  'Rajasthan University of Health Sciences',
  'Rajasthan University of Veterinary & Animal Sciences',
  'Rajendra Agricultural University',
  'Rajendra University',
  'Rajiv Gandhi National Aviation University',
  'Rajiv Gandhi Proudyogiki Vishwavidyalaya',
  'Rajiv Gandhi University',
  'Rajiv Gandhi University of Health Sciences',
  'Rajiv Gandhi University of Knowledge Technologies',
  'Rajiv Gandhi University of Knowledge Technologies Andhra Pradesh',
  'Rajmata Vijayaraje Scindia Krishi Vishwavidyalaya',
  'Ram Krishana Dharmarth Foundation University',
  'Rama Devi Women’s University',
  'Rama University',
  'Ramakrishna Mission Vivekananda Educational and Research Institute',
  'Ramchandra Chandravansi University',
  'Ranchi University',
  'Rani Channamma University',
  'Rani Durgavati Vishwavidyalaya',
  'Rani Lakshmi Bai Central Agricultural University',
  'Rani Rashmoni Green University',
  'Ras Bihari Bose Subharti University',
  'Ravenshaw University',
  'Rayalaseema University',
  'Rayat Bahra University',
  'Renaissance University',
  'Reva University',
  'RIMT University',
  'Rishihood University NH-44(GT Road)',
  'RKDF University',
  'RV University',
  'S.R.M. Institute of Sciences and Technology',
  'Sabarmati University',
  'Sadhu Ram Chand Murmu University of Jhargram (Formerly Jhargram University) Jhargram Raj College',
  'Sage University',
  'Sai Nath University',
  'Sai Tirupati University',
  'Sai University',
  'SAM Global University',
  'Sam Higginbottom Institute of Agriculture',
  'Sambalpur University',
  'Sampurnanand Sanskrit Vishwavidyalaya',
  'Sanchi University of Buddhist-Indic Studies',
  'Sandip University',
  'Sangai International University',
  'Sangam University',
  'Sanjay Ghodawat University',
  'Sanjeev Agrawal Global Educational University',
  'Sankalchand Patel University',
  'Sanskriti University',
  'Sant Baba Bhag Singh University',
  'Sant Gadge Baba Amravati University',
  'Sant Gahira Guru Vishwavidyalaya (formerly Sarguja University)',
  'Sant Longowal Institute of Engineering and Technology (SLIET)',
  'Santosh',
  'Sardar Beant Singh State University',
  'Sardar Bhagwan Singh University',
  'Sardar Krushinagar Dantiwada Agricultural University',
  'Sardar Patel University',
  'Sardar Patel University of Police',
  'Sardar Vallabh Bhai Patel University of Agriculture & Technology',
  'Sardar Vallabhbhai Patel Cluster University',
  'Sarla Birla University',
  'Sarvajanik University',
  'Sarvepalli Radhakrishnan University',
  'Satavahana University',
  'Sathyabama Institute of Science and Technology',
  'Saurashtra University',
  'Saveetha Amaravati University',
  'Saveetha Institute of Medical and Technical Sciences',
  'Savitribai Phule Pune University',
  'Seacom Skills University',
  'Shaheed Bhagat Singh State University',
  'Shaheed Mahendra Karma Vishwavidyalaya',
  'Shaheed Nandkumar Patel Vishwavidyalaya',
  'Shanmugha Arts',
  'Sharda University',
  'Sharnbasva University',
  'Sher-e-Kashmir University of Agricultural Science & Technology',
  'Shiksha ‘O’ Anusandhan',
  'Shiv Nadar University',
  'Shivaji University',
  'Shobhit Institute of Engineering & Technology',
  'Shobhit University',
  'Shoolini University of Biotechnology and Management Sciences',
  'Shree Guru Gobind Singh Tricentenary University',
  'Shree Sankaracharya University of Sanskrit',
  'Shree Somnath Sanskrit University',
  'Shreyarth University',
  'Shri Dharmasthala Manjunatheshwara University',
  'Shri Govind Guru University',
  'Shri Guru Ram Rai University',
  'Shri Jagannath Sanskrit Vishwavidyalaya',
  'Shri Jagdish Prasad Jhabarmal Tibrewala University',
  'Shri Kallaji Vedic Vishvavidyalaya',
  'Shri Khushal Das University',
  'Shri Krishna AYUSH University',
  'Shri Krishna University',
  'Shri Lal Bahadur Shastri National Sanskrit University',
  'Shri Mata Vaishno Devi University',
  'Shri Ramasamy Memorial University',
  'Shri Ramswaroop Memorial University',
  'Shri Rawatpura Sarkar University',
  'Shri Shankaracharaya Professional University',
  'Shri Vaishnav Vidyapeeth Vishwavidyalaya',
  'Shri Venkateshwara University',
  'Shri Vishwakarma Skill University (Formerly Haryana Vishwakarma Skill University) (Dudhola',
  'Shridhar University',
  'Shyam University',
  'Siddharth University',
  'Sidho-Kanho-Birsha University',
  'Sido Kanhu Murmu University',
  'Sikkim Alpine University (Formerly EIILM University)',
  'Sikkim Manipal University',
  'Sikkim National Law University',
  'Sikkim Professional University (Formerly Vinayaka Missions Sikkim University)',
  'Sikkim Skill University',
  'Sikkim University',
  'Silver Oak University',
  'Singhania University',
  'Sir Padmapat Singhania University',
  'Sister Nivedita University',
  'Smt. Nathibai Damodar Thackersey Women’s University',
  'Soban Singh Jeena University',
  'Somaiya Vidyavihar University',
  'South Asian University',
  'Spicer Adventist University',
  'SR University',
  'Sreenarayanaguru Open University',
  'Sri Aurobindo University',
  'Sri Balaji University',
  'Sri Balaji Vidyapeeth',
  'Sri Chandrasekharandra Saraswati Vishwa Mahavidyalaya',
  'Sri Dev Suman Uttarakhand Vishwavidyalay',
  'Sri Devraj Urs Academy of Higher Education and Research',
  'Sri Guru Granth Sahib World University',
  'Sri Guru Ram Das University of Health Sciences',
  'Sri Guru Teg Bahadur State University of Law',
  'Sri Konda Laxman Telangana State Horticultural University',
  'Sri Krishnadevaraya University',
  'Sri P.V. Narsimha Rao Telangana Veterinary University',
  'Sri Padmavati Mahila Vishwavidyalayam',
  'Sri Ramachandra Medical College and Research Institute',
  'Sri Sai University',
  'Sri Sathya Sai Institute of Higher Learning',
  'Sri Sathya Sai University for Human Excellence',
  'Sri Satya Sai University of Technology & Medical Sciences',
  'Sri Siddhartha Academy of Higher Education',
  'Sri Sri Anirudhadeva Sports University',
  'Sri Sri University',
  'Sri Venkateswara Institute of Medical Sciences',
  'Sri Venkateswara University',
  'Sri Venkateswara Vedic University',
  'Sri Venkateswara Veterinary University',
  'Srimanta Sankaradeva University of Health Sciences',
  'Srinath University',
  'Srinivas University',
  'SRM University',
  'St. Joseph University',
  'St. Peter’s Institute of Higher Education and Research',
  'St. Xavier’s University',
  'Starex University',
  'Sumandeep Vidyapeeth',
  'Sunrise University',
  'Surajmal University',
  'Surendranagar University',
  'Suresh Gyan Vihar University',
  'Sushant University (Formerly Ansal University)',
  'Swami Rama Himalayan University',
  'Swami Ramanand Teerth Marathwada University',
  'Swami Vivekanand Subharti University',
  'Swami Vivekanand University',
  'Swami Vivekananda University',
  'Swami Vivekananda Yoga Anusandhana Sansthana',
  'Swarnim Gujarat Sports University',
  'Swarnim Startup & Innovation University',
  'SYMBIOSIS International',
  'Symbiosis Skills and Professional University (Formerly Symbiosis Skills and Open University)',
  'Symbiosis University of Applied Sciences',
  'T.M. University',
  'Tamil Nadu Teacher Education University',
  'Tamil University',
  'Tamilnadu Agricultural University',
  'Tamilnadu Dr. Ambedkar Law University',
  'Tamilnadu Dr. M.G.R. Medical University',
  'Tamilnadu Fisheries University',
  'Tamilnadu Music and Fine Arts University',
  'Tamilnadu National Law School',
  'Tamilnadu Open University',
  'Tamilnadu Physical Education and Sports University',
  'Tamilnadu Veterinary & Animal Sciences University',
  'Tantia University',
  'Tata Institute of Fundamental Research',
  'Tata Institute of Social Sciences',
  'Team Lease Skills University',
  'Techno Global University',
  'Techno India University',
  'Teerthanker Mahaveer University',
  'Telangana University',
  'TERI School of Advanced Studies',
  'Tezpur University',
  'Thapar Institute of Engineering & Technology',
  'The Assam Kaziranga University',
  'The Assam Royal Global University',
  'The Central Sanskrit University',
  'The Charutar Vidya Mandal(CVM) University',
  'The English and Foreign Languages University',
  'The Global Open University',
  'The Global University',
  'The Glocal University',
  'The I.K. Gujaral Punjab Technical University',
  'The Indira Gandhi National Tribal University',
  'The Indira Gandhi Technological & Medical Sciences University',
  'The Institute of Chartered Financial Analysts of India University',
  'The LNM Institute of Information Technology',
  'The Maharaja Bhupinder Singh Punjab Sports University',
  'The National Sanskrit University',
  'The Neotia University',
  'The Northcap University (Formerly ITM University)',
  'The Rajiv Gandhi National University of Law',
  'The Rashtrasant Tukadoji Maharaj Nagpur University',
  'The Sanskrit College and University',
  'The University of Trans-Disciplinary Health Sciences and Technology',
  'The West Bengal National University of Juridical Science',
  'The West Bengal University of Health Sciences',
  'The West Bengal University of Teachers’ Training',
  'Thiruvalluvar University',
  'Thunchath Ezhuthachan Malayalam University',
  'Tilak Maharashtra Vidyapeeth',
  'Tripura University',
  'Tumkur University',
  'U.P. King George’s University of Dental Science',
  'U.P. Rajarshi Tandon Open University',
  'UKA Tarsadia University',
  'United University',
  'University of Agricultural Sciences',
  'University of Allahabad',
  'University of Burdwan',
  'University of Calcutta',
  'University of Calicut',
  'University of Delhi',
  'University of Engineering & Management',
  'University of Engineering and Management',
  'University of Engineering and Technology Roorkee',
  'University of Gour Banga',
  'University of Horticultural Sciences',
  'University of Hyderabad',
  'University of Jammu',
  'University of Kalyani',
  'University of Kashmir',
  'University of Kerala',
  'University of Kota',
  'University of Ladakh',
  'University of Lucknow',
  'University of Madras',
  'University of Mumbai',
  'University of Mysore',
  'University of North Bengal',
  'University of Patanjali',
  'University of Petroleum and Energy Studies',
  'University of Rajasthan',
  'University of Science & Technology',
  'University of Technology',
  'UPL University of Sustainable Technology',
  'Usha Martin University',
  'Utkal University',
  'Utkal University of Culture',
  'Uttar Banga Krishi Vishwavidyalaya',
  'Uttar Pradesh Pandit Deen Dayal Upadhyaya Pashu Chikitsa Vigyan Vishwavidyalaya Evam Go-Anusandhan Sansthan',
  'Uttar Pradesh University of Medical Sciences',
  'Uttar Pradesh Viklang Uddhar Dr. Shakuntala Misra University',
  'Uttarakhand Ayurved University',
  'Uttarakhand Open University',
  'Uttarakhand Sanskrit University',
  'Uttaranchal University',
  'Uttrakhand Technical University',
  'Vanita Vishram Women’s University',
  'Vardhman Mahaveer Open University',
  'Veer Bahadur Singh Purvanchal University',
  'Veer Chandra Singh Garhwali Uttarakhand University of Horticulture & Forestry',
  'Veer Kunwar Singh University',
  'Veer Narmad South Gujarat University',
  'Veer Surendra Sai Institute of Medical Sciences and Research',
  'Veer Surendra Sai University of Technology',
  'Vel Tech Rangarajan Dr. Sagunthala R & D Institute of Science and Technology',
  'Vel’s Institute of Science',
  'Vellore Institute of Technology',
  'Venkateshwara Open University',
  'Vidyasagar University',
  "Vignan's Foundation for Science",
  'Vijayanagara Sri Krishnadevaraya University',
  'Vijaybhoomi University',
  'Vikram University',
  'Vikrama Simhapuri University',
  'Vinayaka Mission’s Research Foundation',
  'Vinoba Bhave University',
  'Vishwa Bharati University',
  'Vishwakarma University',
  'Visveswaraiah Technological University',
  'VIT Bhopal University',
  'VIT-AP University',
  'Vivekananda Global University',
  'West Bengal State University',
  'West Bengal University of Animal and Fishery Sciences',
  'William Carey University',
  'World University of Design',
  'Woxsen University',
  'XIM University (Formerly Xavier University)',
  'Yashwant Rao Chavan Maharashtra Open University',
  'YBN University',
  'Yenepoya',
  'Yogi Vemana University'
];

const yearOfPassingProfile = [
  '1960',
  '1961',
  '1962',
  '1963',
  '1964',
  '1965',
  '1966',
  '1967',
  '1968',
  '1969',
  '1970',
  '1971',
  '1972',
  '1973',
  '1974',
  '1975',
  '1976',
  '1977',
  '1978',
  '1979',
  '1980',
  '1981',
  '1982',
  '1983',
  '1984',
  '1985',
  '1986',
  '1987',
  '1988',
  '1989',
  '1990',
  '1991',
  '1992',
  '1993',
  '1994',
  '1995',
  '1996',
  '1997',
  '1998',
  '1999',
  '2000',
  '2001',
  '2002',
  '2003',
  '2004',
  '2005',
  '2006',
  '2007',
  '2008',
  '2009',
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
  '2029',
  '2030'
];

const profileUrl = {
  get: `${vLearnApiUrl}/profile/phone`,
  post: `${vLearnApiUrl}/profile`
};

export {
  profileSteps,
  profileWidgetDefault,
  universityNames,
  allCities,
  yearOfPassingProfile,
  profileUrl,
  parseLSQData
};
