import { useLocation } from 'react-router-dom';
import { differenceInDays, format } from 'date-fns';
import { toast } from 'react-toastify';
import resourceEmptyImage from '../asset/images/resource_notfound.svg';
import unreadNotFound from '../asset/images/unreadTab_notfound.svg';
import bookmarkNotFound from '../asset/images/bookmark_notfound.svg';
import gesturingGrade from '../asset/images/gesturingGrade.png';
import checkCircleProgress from '../asset/images/check-circle-progress.svg';
import gesture_ok from '../asset/icons/gesture_ok.png';

const constantLogin = {
  emptyMessage: 'Please enter a valid mobile number or email address.',
  emptyMessageOtp: 'Please enter a valid OTP.'
};

const constantSignUp = {
  emptyMessage: 'Please enter a valid mobile number.'
};

//format: Monday, 2 September 2021
const DATE_WEEK_STRING_FORMAT = 'EEEE, do MMMM yyyy';
const DATE_WEEKTIME_STRING_FORMAT = 'EEEE, do MMMM yyyy HH:mm a';
const ACCESS_TYPE = {
  Premium: 'Premium',
  Free: 'Free'
};
const formatDate = (dateString, outputFormat) => {
  const date = new Date(dateString);
  const formattedDate = format(date, outputFormat);
  return formattedDate;
};
const courseStatusVal = {
  Recommended: 'Recommended',
  Ongoing: 'Ongoing',
  Locked: 'Locked',
  Unlocked: 'Unlocked',
  Completed: 'Completed'
};
// To get the status text and icon for the course module.
// status:number
const courseStatus = (status) => {
  switch (status) {
    case courseStatusVal.Recommended:
      return {
        icon: <span className='material-symbols-outlined'>hotel_class</span>
      };
    case courseStatusVal.Ongoing:
      return {
        icon: <span className='material-symbols-outlined'>play_arrow</span>
      };
    case courseStatusVal.Locked:
      return {
        disable: true,
        icon: <span className='material-symbols-outlined'>lock</span>
      };
    case courseStatusVal.Unlocked:
      return {
        icon: <span className='material-symbols-outlined'>lock_open</span>
      };
    case courseStatusVal.Completed:
      return {
        icon: <span className='material-symbols-outlined'>done</span>
      };
    default:
      return false;
  }
};
const activityIcon = (val) => {
  switch (val) {
    case 'quiz':
      return (
        <span className='material-symbols-outlined icon'>contact_support</span>
      );

    case 'assign':
      return <span className='material-symbols-outlined icon'>assignment</span>;

    case 'zoom':
      return <span className='material-symbols-outlined icon'>live_tv</span>;

    case 'videofile':
      return (
        <span className='material-symbols-outlined icon'>play_circle</span>
      );

    case 'folder':
      return (
        <span className='material-symbols-outlined icon'>folder_open</span>
      );

    case 'url':
      return <span className='material-symbols-outlined icon'>link</span>;

    case 'forum':
      return <span className='material-symbols-outlined icon'>forum</span>;

    case 'scrom':
      return <span class='material-symbols-outlined'>co_present</span>;

    case 'hvp':
      return <span class='material-symbols-rounded icon'>award_star</span>;

    case 'page':
      return <span className='material-symbols-rounded icon'>draft</span>;

    case 'resource':
      return <span className='material-symbols-rounded icon'>lab_profile</span>;

    case 'feedback':
      return <span class='material-symbols-outlined icon'>reviews</span>;

    default:
      return <span class='material-symbols-rounded icon'>web_asset</span>;
  }
};
const PROGRESS_TAB = {
  progress: 1,
  grade: 3
};
const getUrlQueryParams = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryParams = {};

  // Iterate over each query parameter and add it to the object
  for (let param of searchParams.entries()) {
    const [key, value] = param;
    queryParams[key] = value;
  }

  return queryParams;
};

const searchPopover = [
  { title: 'All', id: 0 },
  { title: 'Classes and Sessions', id: 1 },
  { title: 'Learning Resources', id: 2 },
  { title: 'Assignments', id: 3 },
  { title: 'Quizzes', id: 4 },
  { title: 'Topics', id: 5 }
];
const resourceSidebarNavItem = {
  RESOURCE: {
    key: 'resource',
    name: 'All Resources',
    title: 'No resources found',
    imagePath: resourceEmptyImage,
    icon: <span class='material-symbols-outlined icon'>article</span>
  },
  UNREAD: {
    name: 'Unread',
    key: 'unread',
    title: 'You have read all resources',
    imagePath: unreadNotFound,
    icon: <span class='material-symbols-outlined icon'>wysiwyg</span>
  },
  SAVED: {
    name: 'Saved',
    key: 'saved',
    title: 'Saved resources would appear here',
    imagePath: bookmarkNotFound,
    icon: <span class='material-symbols-outlined icon'>bookmark</span>
  }
};

function calculatePercentage(value, total) {
  if (total === 0) {
    return '--';
  }

  var percentage = (value / total) * 100;

  if (Number.isInteger(percentage)) {
    return percentage.toFixed(0);
  } else {
    // Check if the percentage ends with ".00"
    if (percentage.toFixed(2).endsWith('.00')) {
      return percentage.toFixed(0);
    } else {
      return percentage.toFixed(2);
    }
  }
}

const CURRENT_INDUSTRY = [
  'Select an Option',
  'Analytics/KPO/Research',
  'BPO/Call Centre',
  'IT Services & Consulting',
  'Electronic Components/Semiconductors',
  'Electronics Manufacturing',
  'Electronics Manufacturing Services(EMS)',
  'Emerging Technologies',
  '3D Printing',
  'AI/ML',
  'Blockchain',
  'Cloud',
  'Cybersecurity',
  'Drones/Robotics',
  'IOT',
  'Nanotechnology',
  'Hardware & Networking',
  'Internet',
  'E-Commerce',
  'OTT',
  'Software Product',
  'Banking',
  'Financial Services',
  'Asset Management',
  'Broking',
  'FinTech/Payments',
  'Insurance',
  'Investment Banking/Venture Capital/Private Equity',
  'NBFC',
  'Micro Finance NBFC',
  'Education/Training',
  'E-Learning/EdTech',
  'Auto Components',
  'Tyre',
  'Automobile',
  'Automobile Dealers',
  'Electric Vehicle(EV)',
  'Building Material',
  'Cement',
  'Ceramic',
  'Glass',
  'Chemicals',
  'Paints',
  'Defence & Aerospace',
  'Electrical Equipment',
  'Fertilizers/Pesticides/Agro chemicals',
  'Industrial Automation',
  'Industrial Equipment/Machinery',
  'Construcion Equipment',
  'Machine Tools',
  'Iron & Steel',
  'Metals & Mining',
  'Packaging & Containers',
  'Petrochemical/Plastics/Rubber',
  'Pulp & Paper',
  'Aviation',
  'Courier/Logistics',
  'Logistics/Tech',
  'Engineering & Construction',
  'Oil & Gas',
  'Ports & Shipping',
  'Shipbuilding',
  'Power ',
  'Hydro Power ',
  'Nuclear Power',
  'Solar Power ',
  'Wind Power',
  'Railways',
  'Real Estate',
  'Co-working',
  'Urban Transport',
  'Water Treatment/Waste Management',
  'Beauty & Personal Care',
  'Beverage',
  'Brewery/Distillery/Beverage',
  'Consumer Electronics & Appliances',
  'Fitness & Wellness',
  'FMCG',
  'Tobacco',
  'Food Processing',
  'Meat/Poultry',
  'Sugar',
  'Furniture & Furnishing',
  'Gems & Jewellery',
  'Hotels & Restaurants',
  'Leather',
  'Retail',
  'Textile & apparel',
  'Fashion',
  'Handicraft',
  'Home Textile',
  'Technical Textile',
  'Yarn & Fabric',
  'Travel & Tourism',
  'Biotechnology',
  'Clinical Research/Contract Research',
  'Medical Devices & Equipment',
  'Medical Services/Hospital',
  'Diagnostics',
  'Pharmaceutical & Life Sciences',
  'Advertising & Marketing',
  'Digital Marketing',
  'Public Relations',
  'Animation & VFX',
  'Events/Live Entertainment',
  'Film/Music/Entertainment',
  'Gaming',
  'Printing & Publishing',
  'Sports/Leisure & Recreation',
  'Telecom/ISP',
  'TV/Radio',
  'Accounting/Auditing',
  'Architecture/Interior Design',
  'Content Development/Language',
  'Design',
  'Facility Management Services',
  'Law Enforcement/Services',
  'Legal',
  'Management Consulting',
  'Recruitment/Staffing',
  'Agriculture/Forestry/Fishing',
  'Agri-tech',
  'Government/Public Administration',
  'Import & Export',
  'Miscallaneous',
  'NGO/Social Services/Industry Associations',
  'Other'
];
const PREFERRED_CITY_LIST = [
  'Ahmedabad',
  'Bengaluru',
  'Bhopal',
  'Bhubaneshwar',
  'Chandigarh',
  'Chennai',
  'Cochin',
  'Coimbatore',
  'Dehradun',
  'Delhi',
  'Gandhinagar',
  'Ghaziabad',
  'Gurugram',
  'Guwahati',
  'Gwalior',
  'Hyderabad',
  'Indore',
  'Jaipur',
  'Kochi',
  'Kolkata',
  'Madurai',
  'Mohali',
  'Mumbai',
  'Mysore',
  'Nagpur',
  'Nashik',
  'Navi Mumbai',
  'Noida',
  'Patna',
  'Pune',
  'Raipur',
  'Rajkot',
  'Ranchi',
  'Surat',
  'Trivandrum',
  'Vadodara',
  'Vishakhapatnam'
];
const SOCIAL_PROFILE = ['Select an option', 'LinkedIn', 'GitHub', 'Kaggle'];
const SKILLS_PLACEHOLDER = [
  'e.g. Leadership',
  'e.g. SQL',
  'e.g. Corporate Finance'
];
const UG_COURSE_LIST = [
  'B.Tech/B.E.',
  'B.Com',
  'B.Sc',
  'B.A',
  'Diploma',
  'B.Arch',
  'B.B.A/B.M.S',
  'B.Des.',
  'B.Ed',
  'B.EI.Ed',
  'B.P.Ed',
  'B.Pharma',
  'B.U.M.S',
  'BAMS',
  'BCA',
  'BDS',
  'BFA',
  'BHM',
  'BHMCT',
  'BHMS',
  'BVSC',
  'LLB',
  'MBBS',
  'IIM 5-year Integrated Mgmt. Programme',
  'Others'
];
const PG_COURSE_LIST = [
  'MBA/PGDM',
  'M.Tech',
  'MS/M.Sc(Science)',
  'MVA',
  'M.Com',
  'PG Diploma',
  'MA',
  'CA',
  'CS',
  'DM',
  'ICWA(CMA)',
  'Integrated PG',
  'LLM',
  'M.Arch',
  'M.Ch',
  'M.Des',
  'M.Ed',
  'M.Pharma',
  'MCM',
  'MDS',
  'Medical-MS/MD',
  'MFA',
  'MVCS',
  'Others'
];
const handleLastDecimalChange = (e) => {
  let value = e.target.value;
  if (value[value.length - 1] === '.') {
    value = value.substring(0, value.length - 1);
  }
  e.target.value = value;
  return e;
};

function getDaysDiff(startDate, endDate) {
  const diffInDays = differenceInDays(endDate, startDate);

  return diffInDays;
}
const DEFAULT_SOCIAL_PROFILE_SELECT_OPTION = 'Select an option';

const parseQueryParams = (queryString) => {
  const urlParams = new URLSearchParams(queryString);
  const queryParams = {};
  for (const [key, value] of urlParams.entries()) {
    queryParams[key] = value;
  }
  return queryParams;
};

const htmlDataParser = (htmlData) => {
  // Create a new HTML document
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlData, 'text/html');

  // Get the text content of the root element
  const textContent = htmlDoc.documentElement.textContent;
  return textContent;
};

const getIp = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    toast.error('Failed to retrieve IP address.');
  }
};
const parseInteger = (input) => {
  if (input?.length) {
    const result = parseFloat(input);
    return isNaN(result) ? input : result;
  }
  return input;
};
const PER_PAGE = 10;

const progressSectionCardDetails = [
  {
    title: '-',
    subTitle: 'My Grade',
    content: 'Grades are not available at the moment',
    badgeIcon: gesture_ok,
    badgeContent: '',
    icon: gesturingGrade,
    titlePostFix: '%',
    tooltipContent:
      'Your Program Grade till date across all courses where grading is enabled. You must achieve a minimum of 60% grade for program completion',
    recenttooltipContent:
      'Your Program Grade till date across all courses where grading is enabled. You must achieve a minimum of 60% grade for program completion'
  },
  {
    title: '-',
    subTitle: 'Attendance',
    contentAtt: 'Attendance is not available at the moment',
    badgeIcon: '../../asset/icons/check_circle.png',
    badgeContent: '',
    iconAtt: checkCircleProgress,
    titlePostFix: '%',
    tooltipContent:
      'Your attendance for the mandatory lectures and other sessions'
  }
];

const SUPPORTED_PAGE_ACTIVITY = {
  page: {
    title: 'page',
    icon: <span class='material-symbols-outlined'>contract</span>
  },
  quiz: {
    title: 'quiz',
    icon: <span class='material-symbols-outlined'>contact_support</span>
  }
};
const QUIZ_TYPE = {
  truefalse: 'True False',
  multichoice: 'Mutiple choice (Multiple correct)',
  singlechoice: 'Mutiple choice (Single correct)',
  essay: 'Essay',
  shortanswer: 'Short answer'
};
const QUIZ_ANSWER_STATUS = {
  1: {
    title: 'Your answer is correct',
    key: ''
  },
  2: {
    title: 'Your answer is partially correct'
  },
  0: {
    title: 'Your answer is incorrect'
  },
  '-1': {
    title: ''
  }
};

const getFileType = (file, uploaded = false, token) => {
  const fileTypeParts = !uploaded
    ? file?.type?.split('/')
    : file?.mimetype?.split('/');
  const fileExtension =
    fileTypeParts?.length > 1 ? fileTypeParts[1] : file?.type || file?.mimetype;
  if (fileExtension === 'pdf') {
    return (
      <span class='material-symbols-outlined' style={{ color: '#F40F02' }}>
        picture_as_pdf
      </span>
    );
  } else if (
    ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'svg+xml'].includes(fileExtension)
  ) {
    return (
      <img
        src={
          file?.fileurl?.length
            ? file?.fileurl.replace(
                '/pluginfile.php',
                '/webservice/pluginfile.php'
              ) +
              `&token=` +
              token
            : URL.createObjectURL(file)
        }
        className='img-img'
      />
    );
  } else if (['mp4', 'avi', 'mkv', 'mov'].includes(fileExtension)) {
    return <span class='material-symbols-outlined'>play_circle</span>;
  } else {
    return <span class='material-symbols-outlined'>description</span>;
  }
};
const data = [
  {
    title: 'Live Sessions',
    key: 'Lectures',
    required: true,
    toolTipContent: `Attendance in ‘live sessions’ is mandatory for program completion`,
    type: 1,
    color: '#FAB300'
  },
  {
    title: 'Doubt Clearing Sessions',
    key: 'doubt',
    toolTipContent:
      'These sessions are optional but important for your learning',
    type: 2,
    color: '#5095FC',
    required: false
  },
  {
    title: 'Practice Sessions',
    key: 'practice',
    toolTipContent:
      'These sessions are optional but important for your learning',
    type: 1,
    color: '#8816BD',
    required: false
  },
  {
    title: 'Other Sessions',
    key: 'other',
    required: false,
    toolTipContent:
      'These sessions are optional but important for your learning',
    type: 2,
    color: '#F8752B'
  }
];
const carreerSession = [
  {
    title: 'Placement & Orientation Sessions',
    key: 'Placement',
    required: true,
    toolTipContent:
      'Attendance in these sessions is mandatory for placement eligibility. You must have 100% attendance in these sessions.',
    type: 1,
    color: '#FAB300'
  },
  {
    title: 'Career Practice Sessions',
    key: 'career_practice',
    toolTipContent: 'These sessions are optional but important for your career',
    type: 2,
    color: '#5095FC',
    required: false
  }
];

const Resume_Approval_Status = {
  0: {
    title: <p>Verification Pending</p>,
    icon: <span class='material-symbols-rounded'>schedule</span>,
    className: 'resume-pending'
  },
  1: {
    title: <p>Verified</p>,
    icon: <span class='material-symbols-outlined filled'>check_circle</span>,
    className: 'resume-success'
  },
  2: {
    title: <p>Rejected</p>,
    icon: <span class='material-symbols-outlined filled'>cancel</span>,
    className: 'resume-reject'
  }
};

export const extensions = {
  applicationExtensions: [
    'zip',
    'pdf',
    'json',
    'xml',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'js',
    'pptx'
  ],
  imageExtensions: [
    'png',
    'jpeg',
    'jpg',
    'gif',
    'svg',
    'webp',
    'bmp',
    'tiff',
    'tif'
  ],

  audioExtensions: ['mp3', 'wav', 'ogg', 'weba', 'aac'],
  videoExtensions: ['mp4', 'webm', 'ogv', 'mov', 'avi'],
  textExtensions: ['txt', 'html', 'htm', 'css', 'js', 'csv']
};

export {
  carreerSession,
  data,
  constantLogin,
  constantSignUp,
  formatDate,
  DATE_WEEK_STRING_FORMAT,
  courseStatus,
  getUrlQueryParams,
  activityIcon,
  searchPopover,
  CURRENT_INDUSTRY,
  PREFERRED_CITY_LIST,
  SOCIAL_PROFILE,
  SKILLS_PLACEHOLDER,
  UG_COURSE_LIST,
  PG_COURSE_LIST,
  handleLastDecimalChange,
  getDaysDiff,
  courseStatusVal,
  DEFAULT_SOCIAL_PROFILE_SELECT_OPTION,
  parseQueryParams,
  htmlDataParser,
  parseInteger,
  ACCESS_TYPE,
  PROGRESS_TAB,
  getIp,
  resourceSidebarNavItem,
  PER_PAGE,
  SUPPORTED_PAGE_ACTIVITY,
  DATE_WEEKTIME_STRING_FORMAT,
  QUIZ_TYPE,
  progressSectionCardDetails,
  QUIZ_ANSWER_STATUS,
  getFileType,
  calculatePercentage,
  Resume_Approval_Status
};
