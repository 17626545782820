export const CLEVER_TAP_EVENTS = {
  Login_Attempt: 'Login_Attempt',
  LogIn: 'LogIn',
  HOME_ACTIVITY: 'Home_Activity',
  Resources: 'Resources',
  Bookmarked: 'Bookmarked',
  Resources_Detail_Page: 'Resources_Detail _Page',
  Profile: 'Profile',
  Notifications: 'Notifications',
  Progress: 'Progress',
  Progress_Details: 'Progress_Details',
  Grades_details_activity: 'Grades_details_activity',
  Attendance_details_activity: 'Attendance_details_activity',
  Quiz_Review: 'Quiz_Review',
  Class: 'Class',
  Program_Timeline: 'Program_Timeline',
  Program_Timeline_Activity: 'Program_Timeline_Activity',
  Search: 'Search',
  Search_Filters_Applied: 'Search_Filters_Applied',
  Search_Result_Clicked: 'Search_Result_Clicked',
  Course_Timeline_Activity: 'Course_Timeline_Activity',
  Activity_Started: 'Activity_Started',
  Activity_Submitted: 'Activity_Submitted',
  Activity_Finish: 'Activity_Finish',
  Page_Activity: 'Page_Activity',
  App_Benefits_Action: 'App_Benefits_Action',
  Feedback_Action: 'Feedback_Action',
  Feedback_Submitted: 'Feedback_Submitted',
  Elective: 'Elective',
  Refer_And_Earn: 'Refer_And_Earn'
};
export const defaultCleverTapValue = 'NA';
export const HOME_SECTIONS = {
  Welcome: {
    section: 'Welcome',
    activity: {
      Reminder_Assignment_Clicked: 'Reminder_Assignment_Clicked',
      Reminder_Class_Clicked: 'Reminder_Class_Clicked',
      Reminder_Quiz_Clicked: 'Reminder_Quiz_Clicked',
      Select_Elective: 'Select_Elective'
    }
  },
  Program: {
    section: 'Program',
    activity: {
      Continue_Learning: 'Continue_Learning',
      Expand_Courses: 'Expand_Courses',
      Collapse_Courses: 'Collapse_Courses',
      More_Courses: 'More_Courses'
    }
  },
  Calendar: {
    section: 'Calendar',
    activity: {
      Calendar_Event_Class: 'Calendar_Event_Class',
      Calendar_Event_Assignment: 'Calendar_Event_Assignment',
      Calendar_Event_Quiz: 'Calendar_Event_Quiz',
      Calendar_Sync: 'Calendar_Sync',
      Calendar_Filter: 'Calendar_Filter'
    }
  },
  Classes: {
    section: 'Classes',
    activity: {
      Classes_View_All: 'Classes_View_All',
      cancelled: 'Classes_Cancelled',
      scheduled: 'Classes_Scheduled',
      ongoing: 'Classes_Ongoing',
      'past meeting': 'Classes_Past_Meeting'
    }
  },
  Assignments: {
    section: 'Assignments',
    activity: {
      Assignment_Item: 'Assignment_Item',
      Assignment_View_All: 'Assignment_View_All',
      Assignments_Submitted: 'Assignments_Submitted',
      graded: 'Assignments_Graded',
      scheduled: 'Assignments_Scheduled',
      due: 'Assignments_Due',
      'not submitted': 'Assignments_Not_Submitted',
      submitted: 'Assignments_Sumitted'
    }
  },
  Sidebar: {
    section: 'Sidebar',
    activity: {
      Sidebar_Free_Dashboard: 'Sidebar_Free_Dashboard',
      Sidebar_Resources: 'Sidebar_Resources',
      Sidebar_Raise_A_Ticket: 'Sidebar_Raise_A_Ticket',
      Sidebar_Project: 'Sidebar_Project',
      Sidebar_Home: 'Sidebar_Home',
      Sidebar_Careers: 'Sidebar_Careers',
      Refer_Earn_Learn_More: 'Refer_Earn_Learn_More',
      Refer_Earn_View_Referrals: 'Refer_Earn_View_Referrals'
    }
  },
  Resources: {
    section: 'Resources',
    activity: {
      Resources_Item: 'Resources_Item',
      Resources_ViewAll: 'Resources_ViewAll'
    }
  },
  Progress: {
    section: 'Progress'
  },
  Learn: { section: 'Learn' },
  Navigation: {
    section: 'Nav_Bar',
    activity: { Notification_Bell: 'Notification_Bell', Profile: 'Profile' }
  },
  Refer_Earn_Card: { section: 'Refer_Earn_Card' }
};

export const Page_Activity = {
  PAGE: {
    Home: 'Home',
    Learning_Hub: 'Resources',
    Program_Timeline: 'Program_Timeline',
    Course_Overview: 'Course_Overview',
    Course_Contents: 'Course_Contents',
    Course_Activity: 'Course_Activity',
    Resources: 'Resources',
    Resource_Detail: 'Resource_Detail',
    App_Benefits: 'App_Benefits',
    REFER: 'Refer and_Earn'
  },
  Activity_Name: {
    Quiz: 'Quiz',
    Assignment: 'Assignment',
    URL: 'URL',
    File: 'File',
    Folder: 'Folder',
    Zoom: 'Zoom',
    Zoom_Duplicate: 'Zoom_Duplicate',
    DoSelect_Quiz: 'DoSelect_Quiz',
    Feedback: 'Feedback'
  }
};
