import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
// import { Flex, Image } from 'theme-ui';
import { ROUTES } from './config/routes';
// import splashScreen from './asset/images/splashScreenGif.gif';

const Layout = lazy(() => import('./layouts/Layout'));
const Login = lazy(() => import('./layouts/loginLayout/LoginLayout'));

const AuthComponent = lazy(() =>
  import('./components/AuthComponent/AuthComponent')
);
const AttendanceDrawerView = lazy(() =>
  import('./components/DrawerView/AttendanceDrawerView')
);
const PageActivity = lazy(() => import('./features/PageActivity/PageActivity'));
const CalenderSync = lazy(() => import('./features/CalenderSync/CalenderSync'));
const ProgramSearch = lazy(() =>
  import('./features/ProgramSearch/ProgramSearch')
);

const Error500 = lazy(() => import('./pages/Error500'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Error404 = lazy(() => import('./pages/Error404'));
const Error401 = lazy(() => import('./pages/Error401'));
const ProfileWidget = lazy(() => import('./pages/ProfileWidget'));
const ProgramTimeline = lazy(() => import('./pages/ProgramTimeline'));
const ZOOM = lazy(() => import('./pages/Zoom/Zoom'));
const LearningHub = lazy(() => import('./pages/LearningHub'));
const LearningHubArtical = lazy(() =>
  import('./pages/LearningHub/LearningHubArtical')
);
const Quiz = lazy(() =>
  import('./features/PageActivity/QuizActivity/Quiz/Quiz')
);
const ReviewScreen = lazy(() =>
  import('./features/PageActivity/QuizActivity/Review/Review')
);
const ReferNEarn = lazy(() => import('./pages/ReferNEarn'));
const MyReferral = lazy(() => import('./pages/ReferNEarn/MyReferral'));
const Router = () => {
  // Need to refactor this further for auth and main layout splitting
  const routes = [
    {
      path: ROUTES.ERROR_500,
      element: <Error500 />
    },
    {
      path: ROUTES.ERROR_401,
      element: <Error401 />
    },
    {
      path: ROUTES.HOME,
      element: (
        <AuthComponent>
          <Layout />
        </AuthComponent>
      ),
      children: [
        {
          index: true,
          element: <Dashboard />
        },
        {
          path: ROUTES.ATTENDANCE_DETAILS,
          element: <AttendanceDrawerView />
        },
        {
          path: ROUTES.PROGRAM_TIMELINE,
          element: <ProgramTimeline />
        },
        {
          path: ROUTES.PROGRAM_SEARCH,
          element: <ProgramSearch />
        },
        {
          path: ROUTES.LEARNING_HUB,
          element: <LearningHub />
        },
        {
          path: ROUTES.LEARNING_HUB_ARTICAL,
          element: <LearningHubArtical />
        },
        {
          path: ROUTES.CALENDER_SYNC,
          element: <CalenderSync />
        },
        {
          path: ROUTES.REFER_N_EARN,
          element: (
            <AuthComponent>
              <ReferNEarn />
            </AuthComponent>
          )
        },
        {
          path: ROUTES.MY_REFERRALS,
          element: (
            <AuthComponent>
              <MyReferral />
            </AuthComponent>
          )
        },
        {
          path: '*',
          element: <Error404 />
        }
      ]
    },
    {
      path: ROUTES.PAGE_ACTIVITY,
      element: (
        <AuthComponent>
          <PageActivity />
        </AuthComponent>
      )
    },
    {
      path: ROUTES.PROFILE,
      element: (
        <AuthComponent>
          <ProfileWidget />
        </AuthComponent>
      )
    },
    {
      path: ROUTES.ZOOM,
      element: (
        <AuthComponent>
          <ZOOM />
        </AuthComponent>
      )
    },
    {
      path: ROUTES.QUIZ,
      element: (
        <AuthComponent>
          <Quiz />
        </AuthComponent>
      )
    },
    {
      path: ROUTES.QUIZ_REVIEW_SCREEN,
      element: (
        <AuthComponent>
          <ReviewScreen />
        </AuthComponent>
      )
    },

    {
      path: ROUTES.LOGIN,
      element: <Login />
    }
  ];

  const element = useRoutes(routes);
  return element;
};

export default Router;
