const SERVICES = {
  VASSIST_SERVICE: 'vAssistService',
  MOODLE_SERVICE: 'moodleService',
  MOODLE_FREE_VLEARN_SERVICE: 'moodleFreeVlearnService',
  FREE_ARTICLE_SERVICE: 'freeCourseArticle',
  PRIMEROOT_ENROLLMENT_SERVICE: 'PrimerootEnrollmentService',
};

const METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

export { SERVICES, METHODS };
