import { Suspense, useState, useEffect, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Heading, Button, Flex } from 'theme-ui';
import { logout, verifyToken } from './redux/feature/authSlice';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import Router from './Router';
import FreeRouter from './FreeRouter';

import { parseQueryParams } from './constant/constant';
import { backendBaseUrl } from './api/config';

const MaintenancePage = lazy(() =>
  import('./components/MaintenancePage/MaintenancePage')
);

function App() {
  const [enableMaintenanceMode, setEnableMaintenanceMode] = useState(0);

  const dispatch = useDispatch();
  const location = useLocation();

  const checkMaintenanceMode = async () => {
    try {
      const url = `${backendBaseUrl}/login/checkmaintenance.php`;
      const response = await fetch(url);
      const data = await response.json();
      // console.log("DATA", parseInt(data.enabled));
      let enableStatus = parseInt(data.enabled);
      setEnableMaintenanceMode(enableStatus);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    // checkMaintenanceMode();
    const param = parseQueryParams(location.search);
    const token = localStorage.getItem('token');
    const freeToken = localStorage.getItem('freeToken');
    if (param?.logout === 'true') {
      dispatch(logout());
      const newURL = window.location.pathname;
      window.history.replaceState({}, '', newURL);
      return;
    }
    if (token != null && !location.pathname.includes('/free')) {
      const userData = JSON.parse(localStorage.getItem('user'));
      dispatch(verifyToken({ token: token, userData: userData }));
    }
    if ((freeToken == null && param?.token == null) && location.pathname.includes('/free')) {
    
      window.location.replace("https://herovired.com/");
    }
    if ((freeToken != null || param?.token != null) && location.pathname.includes('/free')) {
      let freeTk = param?.token || freeToken;
      dispatch(verifyToken({ token: freeTk, withoutUserData: true }));
    }
    // // Need to fix this ass hole
    // const loadStyles = async () => {
    //   // Dynamically load CSS files
    //   const toastifyStyle = document.createElement('link');
    //   toastifyStyle.rel = 'stylesheet';
    //   toastifyStyle.href = 'react-toastify/dist/ReactToastify.css';
    //   document.head.appendChild(toastifyStyle);
    // };

    // // Call the function to load CSS files after the component mounts
    // loadStyles();
  }, []);
  function ErrorFallback({ error, resetErrorBoundary }) {
    const location = useLocation();
    const err = { text: `${error} at  ${location.pathname}` };
    return (
      <Flex
        variant='appContent'
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '110px',
          backgroundColor: 'accent.light'
        }}
      >
        <Heading as='h5'>Something went wrong</Heading>
        {/* <Image src={ErrorImage} alt='Error'></Image>
        <Heading as='h5'>{error.message}</Heading> */}
        <Button sx={{ marginTop: '25px' }} as={Link} to='/'>
          Return to Dashboard
        </Button>
      </Flex>
    );
  }

  const accessType = useSelector((state) => state?.auth?.userData?.accessType);
  return (
    <>
      {/* <AuthIsLoaded> */}
      <Suspense fallback={ErrorFallback}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {enableMaintenanceMode == 1 ? <MaintenancePage /> : (accessType === 'Free' && location.pathname.includes('/free')) ? FreeRouter() : Router()}
        </ErrorBoundary>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
      </Suspense>
      {/* </AuthIsLoaded> */}
    </>
  );
}
export default App;
