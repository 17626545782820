import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { makeRequest } from '../../api/api';
import { METHODS, SERVICES } from '../../api/constants';
import { commonMoodlePayload } from '../../config';
import {
  MOODLE_BACKEND_ROUTES,
  VASSIST_BACKEND_ROUTES
} from '../../config/apiRoutes';
import { toast } from 'react-toastify';
import { parseLSQData } from '../../constant/profileWidgetConstant';
import { ACCESS_TYPE } from '../../constant/constant';
import { createCleverTapProfile } from '../../utils/cleverTap';

export const fetchPolicyDetails = createAsyncThunk(
  'token/fetchPolicyDetails',
  async (param) => {
    // console.log("fetch param",param)
    try {
      const response = await makeRequest(
        SERVICES.MOODLE_SERVICE,
        '',
        METHODS.POST,
        {
          ...param,
          ...commonMoodlePayload,
          wsfunction: MOODLE_BACKEND_ROUTES.GET_POLICY_DETAILS
        }
      );
      return { data: response.data };
    } catch (e) {}
  }
);

export const postProfileDetails = createAsyncThunk(
  'token/postProfileDetails',
  async (data, thunkApi) => {
    const response = await makeRequest(
      SERVICES.VASSIST_SERVICE,
      VASSIST_BACKEND_ROUTES.POST_PROFILE_DATA,
      METHODS.POST,
      {},
      data
    );
    if (response.status === 200) {
      thunkApi.dispatch(setLSQ({ isLsqData: false, data: {}, dataSync: true }));
      return data;
    }
    return response.data;
  }
);

export const GET_LSQ_PROFILE_DETAILS = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await makeRequest(
        SERVICES.VASSIST_SERVICE,
        VASSIST_BACKEND_ROUTES.GET_LSQ_PROFILE_DETAILS,
        METHODS.GET,
        data
      );
      if (response.status === 200) {
        if (response?.data?.length) {
          const data = await parseLSQData(response?.data[0]);
          if (data?.phone?.length) {
            resolve({ ...response, data: data });
          } else {
            reject({});
          }
        } else {
          reject(response);
        }
      } else {
        reject(response);
      }
    } catch (e) {
      reject(e);
    }
  });
};

// Need to use promise and to make it work for every pre-written code avoiding createasyncthunk and using normal promises
// Due to which need to pass state and dispatch.
// If you move to redux just return where is resolve and thunkApi.rejectWithvalue on reject else all logic will be same.
export const GET_PROFILE_DETAILS = (data, dispatch, lsqDataParam) => {
  return new Promise(async (resolve, reject) => {
    dispatch(setProfileLoading(true));
    if (lsqDataParam?.isLsqData) {
      resolve({ status: 200, data: lsqDataParam?.lsqData });
      dispatch(setProfileLoading(false));
      return;
    }
    try {
      const profileData = await makeRequest(
        SERVICES.VASSIST_SERVICE,
        VASSIST_BACKEND_ROUTES.GET_PROFILE_DATA,
        METHODS.POST,
        {},
        data
      );
      if (profileData.status === 200) {
        dispatch(setProfileData(profileData?.data?.data));
        resolve({ ...profileData, data: profileData?.data?.data });
        const {
          firstName,
          lastName,
          gender,
          phone,
          emailAddress,
          dateOfBirth
        } = profileData?.data?.data;
        const profileCreated = sessionStorage.getItem('profileCreated');
        if (!profileCreated) {
          createCleverTapProfile({
            Name: firstName + lastName,
            Phone: '+91' + phone,
            'First Name': firstName,
            'Last Name': lastName,
            Gender: gender[0],
            DOB: dateOfBirth ? new Date(dateOfBirth.split(',')[0]) : undefined,
            'Phone Number': '+91' + phone,
            Email: emailAddress
          });
        }
        dispatch(setProfileLoading(false));
        return;
      } else if (profileData.status === 204 && !lsqDataParam?.isLsqData) {
        if (lsqDataParam?.accessType === ACCESS_TYPE.Premium) {
          // LSQ api call
          dispatch(lsqLoading(true));
          await GET_LSQ_PROFILE_DETAILS(data)
            .then((res) => {
              dispatch(
                setLSQ({ isLsqData: true, data: res?.data, loading: false })
              );
              resolve(res);
              dispatch(postProfileDetails(res?.data));
            })
            .catch((e) => {
              dispatch(setLSQ({ isLsqData: false, data: {}, loading: false }));
              reject(e);
            });
        }
        resolve(profileData);
        dispatch(setProfileLoading(false));
        return;
      }
    } catch (e) {
      const { response } = e;

      if (response?.status === 404 && !lsqDataParam?.isLsqData) {
        dispatch(lsqLoading(true));
        await GET_LSQ_PROFILE_DETAILS(data)
          .then((res) => {
            dispatch(
              setLSQ({ isLsqData: true, data: res?.data, loading: false })
            );
            resolve(res);
            dispatch(postProfileDetails(res?.data));
          })
          .catch((e) => {
            dispatch(setLSQ({ isLsqData: false, data: {}, loading: false }));
            reject(e);
          });
        dispatch(setProfileLoading(false));
        return;
      }
      reject(e);
      dispatch(setProfileLoading(false));
    }
  });
};
export const postPolicyDetails = createAsyncThunk(
  'token/postPolicyDetails',
  async (param) => {
    try {
      const response = await makeRequest(
        SERVICES.MOODLE_SERVICE,
        '',
        METHODS.POST,
        {
          ...param,
          ...commonMoodlePayload,
          wsfunction: MOODLE_BACKEND_ROUTES.POST_POLICY_DETAILS
        }
      );
      return { data: response.data };
    } catch (e) {}
  }
);
export const userSlice = createSlice({
  name: 'token',
  initialState: {
    userToken: '',
    policyDetails: [],
    policyLoading: false,
    policyAcceptedSuccess: false,
    isLsqData: false,
    lsqData: {},
    isLsqLoading: false,
    isDataSync: false,
    profileLoading: false,
    profileData: {}
  },
  reducers: {
    userToken: (state, action) => {
      state.userToken = action.payload;
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },
    setLSQ: (state, action) => {
      state.isLsqData = action.payload.isLsqData;
      state.lsqData = action.payload.data;
      state.isLsqLoading = action.payload.loading || state.isLsqLoading;
      if (action.payload?.dataSync) {
        state.isDataSync = action.payload.dataSync;
      }
    },
    lsqLoading: (state, action) => {
      state.isLsqLoading = action.payload;
    },
    setProfileLoading: (state, action) => {
      state.isLsqLoading = action.payload;
    }
  },
  extraReducers: (builder) => {
    ``;
    builder.addCase(fetchPolicyDetails.pending, (state) => {
      state.policyLoading = true;
    });
    builder.addCase(fetchPolicyDetails.fulfilled, (state, action) => {
      state.policyLoading = false;
      if (action?.payload?.data?.length) {
        state.policyDetails = action?.payload?.data;
      }
    });
    builder.addCase(fetchPolicyDetails.rejected, (state) => {
      state.policyLoading = false;
    });
    builder.addCase(postPolicyDetails.pending, (state) => {
      state.policyLoading = true;
    });
    builder.addCase(postPolicyDetails.fulfilled, (state, action) => {
      state.policyLoading = false;
      if (action.payload.data.status === 'Success') {
        state.policyAcceptedSuccess = true;
      } else {
        toast.error(action.payload.data.status || 'Something went wrong');
      }
    });
    builder.addCase(postPolicyDetails.rejected, (state) => {
      state.policyLoading = false;
    });
  }
});

// Action creators are generated for each case reducer function
export const {
  userToken,
  setLSQ,
  lsqLoading,
  setProfileLoading,
  setProfileData
} = userSlice.actions;

export default userSlice.reducer;
