export const MOODLE_BACKEND_ROUTES = {
  GET_ELECTIVE_PATH: 'block_programs_get_program_elective',
  POST_ELECTIVE_PATH: 'block_programs_user_elective_selection',
  GET_FEEDBACK_WIDGET_FEEDBACKS: 'block_feedback_widget_user_latest_feedback',
  GET_FEEDBACK_WIDGET_QUESTIONS: 'local_mobile_get_feedback_questions',
  POST_FEEDBACK_WIDGET: 'mod_feedback_process_page',
  GET_PROGRAM_DETAILS: 'block_programs_get_learningpath_about',
  GET_PROGRAMS: 'block_programs_get_learningpath_courses',
  GET_PROGRAMS_SECTION: 'block_programs_get_course_sections',
  GET_PROGRAMS_SECTION_ACTIVITY: 'block_programs_get_sections_activities',
  GET_RECENT_ACTIVITY: 'block_programs_get_course_recentactivities',
  GET_PROGRAM_SEARCH: 'local_program_search_get_program_search',
  GET_POLICY_DETAILS: 'local_consent_form_get_program_consent_content',
  POST_POLICY_DETAILS: 'local_consent_response',
  GET_ALL_NOTIFICATION: 'message_popup_get_popup_notifications',
  POST_NEW_FEATURE_NOTIFICATION:
    'local_feature_alert_feature_notification_view',
  POST_NEW_FEATURE_READ_NOTIFICATION: 'core_message_mark_notification_read',
  GET_RESOURCE_TAGS: 'local_learning_hub_get_user_resourcetags',
  GET_RESOURCE_DATA: 'local_learning_hub_get_learninghub_resources',
  GET_SINGLE_RESOURCE_DATA: 'local_learning_hub_get_resource_info',
  POST_BOOKAMRK_ARTICAL: 'local_learning_hub_save_user_resource_status',
  MARK_ARTICAL_READ: 'local_learning_hub_save_user_resource_status',
  GET_MEETING_DATA: 'mod_zoom_get_class_details',
  MARK_RECENT_ACTIVITY: 'local_apis_user_activity_view',
  GET_QUIZ_DETAILS: 'local_apis_get_quiz_details_user',
  START_QUIZ_GET_QUESTIONS: 'local_apis_get_question_with_start_attempt',
  POST_QUESTION_ANSWER: 'mod_quiz_process_attempt',
  BOOKMARK_QUIZ_QUESTION: 'local_apis_save_flag_questions',
  GET_QUESTION_REVIEW: 'local_apis_get_user_attempt_review',
  GET_MY_REFERRAL: 'block_get_refrals',
  POST_REFERRAL: 'block_add_refree',
  GET_REFER_PROGRAM: 'block_get_program_list'
};

export const FREE_COURSE_BACKEND_ROUTES = {
  GET_ENROLLED_COURSE_LIST: 'local_apis_get_enrolled_free_courses',
  GET_FREE_COURSE_LIST: 'local_apis_get_free_courses',
  GET_FREE_COURSE_MODULES: 'block_programs_get_course_sections',
  GET_MODULE_VIDEOS_LIST: 'block_programs_get_sections_activities',
  ENROLL_FREE_COURSE: 'local_apis_enroll_free_user',
  GET_ACTIVITY_DOWNLOAD_LINK: 'core_course_get_contents',
  FETCH_USER_CERTIFICATE: 'local_apis_get_user_certificate',
  UPDATE_USER_DETAILS: 'local_apis_create_update_enrol_user',
  SET_VIDEO_AS_WATCHED: 'local_apis_update_user_videoprogress'
};

export const VASSIST_BACKEND_ROUTES = {
  GET_PROFILE_DATA: '/profile/phone',
  POST_PROFILE_DATA: '/profile',
  GET_LSQ_PROFILE_DETAILS: '/profile/lsq',
  GET_ZOOM_SIGNATURE: '/zoom/signature'
};
